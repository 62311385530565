// This file can be used for defining incidents related enums

export enum IncidentModalChangeablePropertyType {
    REQUEST_VIDEO = "REQUEST_VIDEO",
    ASSIGNEE = "ASSIGNEE",
    REGO = "REGO",
    SALES_FORCE_ID = "SALES_FORCE_ID",
    STATUS = "STATUS",
}

export enum IncidentGroupByColumn {
    INCIDENT = "incident",
    SITE_ID = "siteId",
}

export enum IncidentType {
    CAR_IN_TAXIRANK = "car_in_taxirank",
    DOUBLE_PARKING = "double_parking",
    STANDBY_TAXI = "standby_taxi",
}

export enum PresetFilterLabels {
    EXCLUDE_T_PLATE_IN_CAR_IN_TAXIRANK = "Exclude T Plate in car_in_taxirank",
    ONLY_T_PLATE_IN_CAR_IN_TAXIRANK = "Only T Plate in car_in_taxirank",
    EXPIRING_WITHIN_SEVEN_DAYS = "Expiring within 7 days",
}

export enum PresetFilterTypeOptions {
    EXCLUDE_REGO_WITH_INCIDENT_TYPE = "excludeRegoWithIncidentType",
    ONLY_REGO_WITH_INCIDENT_TYPE = "onlyRegoWithIncidentType",
    EXPIRING_WITHIN_SEVEN_DAYS = "expiringWithinSevenDays",
}

export enum IncidentVideoStatus {
    LOADING = "Loading",
    VIDEO_UNAVAILABLE = "Video Unavailable",
    PROCESSING_VIDEO = "Processing Video",
}

export enum IncidentVideoAction {
    DOWNLOAD_VIDEO = "Download Video",
    PLAY_VIDEO = "Play Video",
    RETRY_VIDEO_REQUEST = "Retry Video Request",
    REQUEST_VIDEO = "Request Video",
}
