import { ApiEndpoints } from "configs";
import { LocationWithCameraPosition } from "types/camera-position/position";
import { UpdateCameraPositionRequestBody } from "types/camera-position/request";
import { UpdateCameraPositionResponse } from "types/camera-position/response";
import ExecuteAsync from "utils/api-client";

export const executeGetDeviceSnapshotsRequest = async (
    signal?: AbortSignal,
): Promise<LocationWithCameraPosition[] | null> => {
    const response = await ExecuteAsync<undefined, LocationWithCameraPosition[]>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        signal,
        url: "location/v2/device/snapshot",
    });
    return response || null;
};

export const executeUpdateDeviceSnapshots = async (
    deviceId: string,
    signal?: AbortSignal,
): Promise<UpdateCameraPositionResponse | null> => {
    const response = await ExecuteAsync<UpdateCameraPositionRequestBody, UpdateCameraPositionResponse>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "PUT",
        signal,
        url: `location/v2/device/${deviceId}/reference/snapshot`,
    });
    return response || null;
};
