import React, { useEffect } from "react";
import { Box, Container, CssBaseline, Grid } from "@mui/material";
import { Navigate, Outlet, useMatch } from "react-router-dom";

import { appPrefix, appDefaultRoute } from "pages/app-taxi-ranking";
import { useSelector } from "react-redux";
import { LoadingWrapper } from "components/wrappers/LoadingWrapper";
import { useAppDispatch } from "store";
import { setRootPath } from "reducers/auth.reducer";
import SidebarTaxiRank from "./drawer/SidebarTaxiRank";
import NavbarTaxiRank from "./header/NavbarTaxiRank";

const CameraPositionLayout = () => {
    const match = useMatch(appPrefix);
    const { locationsTree } = useSelector((state) => state.newLocation);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setRootPath("/taxi-ranking/admin-camera-comparison"));
    }, []);

    if (match) {
        return <Navigate to={appDefaultRoute} replace />;
    }

    return (
        <Container maxWidth="xl" disableGutters>
            <CssBaseline />
            <NavbarTaxiRank />
            <Grid className="custom-container">
                <SidebarTaxiRank rootPath="/taxi-ranking/admin-camera-comparison" />

                <div className="content">
                    {locationsTree.length > 0 ? (
                        <Outlet />
                    ) : (
                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                            <LoadingWrapper isLoading />
                        </Box>
                    )}
                </div>
            </Grid>
        </Container>
    );
}

export default CameraPositionLayout;
