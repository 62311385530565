import { Divider, styled, Typography } from "@mui/material";
import { LocationWithCameraPosition } from "types/camera-position/position";
import AccordionWrapper from "components/wrappers/AccordionWrapper";
import PaperWrapper from "components/wrappers/PaperWrapper";
import React from "react";
import Devices from "./Devices";

const DEVICES_NOT_AVAILABLE = "Devices not available";
const LOCATIONS_NOT_AVAILABLE = "Locations not available";

const StyledDivider = styled(Divider)(({ theme }) => ({
    margin: "25px 0px 20px 0px",
    width: "100%",
    height: "0.001rem",
    backgroundColor: theme.palette.primary.main,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#21315b",
    width: "100%",
    textAlign: "center",
    minHeight: "20vw",
    [theme.breakpoints.up("xs")]: {
        fontSize: "10px",
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "13px",
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "16px",
    },
}));

const StyledTypographyChip = styled("span")(({ theme }) => ({
    display: "block",
    color: "black",
    width: "fit-content",
    padding: "4px 8px",
    borderRadius: "5px",
    backgroundColor: "gray",
    [theme.breakpoints.up("xs")]: {
        fontSize: "10px",
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "13px",
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "16px",
    },
}));

const StyledLocationNameTypography = styled(Typography)(({ theme }) => ({
    display: "flex",
    color: "#6b439d",
    [theme.breakpoints.up("xs")]: {
        fontSize: "1rem",
        flexWrap: "wrap",
        alignItems: "start",
        justifyContent: "center",
        flexDirection: "column",
        gap: 0,
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "1.2rem",
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "1.3rem",
    },
    [theme.breakpoints.up("lg")]: {
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "row",
        gap: 20,
    },
}));

export interface LocationsProps {
    cameraPositions: LocationWithCameraPosition[];
    isDataUpdating: boolean;
    updateCameraPosition: (deviceId: string) => Promise<void>;
}

const Locations = ({ cameraPositions, updateCameraPosition, isDataUpdating }: LocationsProps) => {
    if (!cameraPositions?.length)
        return (
            <PaperWrapper>
                <StyledTypography>{LOCATIONS_NOT_AVAILABLE}</StyledTypography>
            </PaperWrapper>
        );
    return (
        <>
            {cameraPositions?.map((location) => {
                const { locationName, devices, locationId } = location;
                return (
                    <AccordionWrapper
                        key={`${locationName}-${locationId}`}
                        defaultExpanded={!!devices?.length}
                        disabled={!devices?.length}
                        customStyles={{
                            marginY: "10px",
                            "&.Mui-disabled": {
                                color: "inherit",
                                "& .MuiButtonBase-root": {
                                    opacity: "0.7",
                                },
                            },
                        }}
                        summary={
                            <StyledLocationNameTypography>
                                {locationName}
                                {!devices?.length && (
                                    <StyledTypographyChip key={Math.random()}>
                                        {DEVICES_NOT_AVAILABLE}
                                    </StyledTypographyChip>
                                )}
                            </StyledLocationNameTypography>
                        }
                        details={
                            devices?.length ? (
                                devices.map((deviceDetails, index) => (
                                    <React.Fragment key={`${deviceDetails.deviceName}-${deviceDetails.deviceId}`}>
                                        <Devices
                                            isDataUpdating={isDataUpdating}
                                            device={deviceDetails}
                                            updateDevicePosition={updateCameraPosition}
                                        />
                                        {devices.length - 1 !== index && <StyledDivider key={Math.random()} />}
                                    </React.Fragment>
                                ))
                            ) : (
                                <StyledTypography key={Math.random()} height="10vw">
                                    {DEVICES_NOT_AVAILABLE}
                                </StyledTypography>
                            )
                        }
                    />
                );
            })}
        </>
    );
};

export default Locations;
