import { RequestOptions } from "axios-types";
import { ApiEndpoints } from "configs";
import { DetectionsLatestQuery } from "types";
import { Room } from "types/detections/room";
import ExecuteAsync from "utils/api-client";
import { createQueryParams } from "utils/create-query-params";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
export function executeGetAllRoomStatusRequest(
    data?: DetectionsLatestQuery,
    options?: RequestOptions,
): Promise<Room[]> {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/reporting/detections/values/latest${createQueryParams(data)}`,
        signal: options?.signal,
    });
}
