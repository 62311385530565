import { Navigate, Outlet } from "react-router-dom";
import { NavigateNotFound } from "constants/paths.constants";
import home from "./home";
import bikeRacks from "./bike-racks";
import logs from "./logs";
import notFound from "./not-found";
import cameraPosition from "./camera-position";

export const appPrefix = "cycle-ways";
export const directUrl = "/cycleways";
export const appDefaultRoute = `/${appPrefix}/home`;

const root = [
    {
        path: appPrefix,
        element: <Outlet />,
        children: [
            { path: "", element: <Navigate to="home" replace /> },
            { path: "*", element: <Navigate to={NavigateNotFound} replace /> },
            { ...home },
            { ...bikeRacks },
            { ...logs },
            { ...cameraPosition },
            { ...notFound },
        ],
    },
    {
        path: directUrl,
        element: <Navigate to={appDefaultRoute} replace />,
    },
];

export default root;
