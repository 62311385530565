import { useEffect, useRef, useState } from "react";
import SectionHeading from "components/headings/SectionHeading";
import { AiFillAppstore } from "react-icons/ai";
import PaperWrapper from "components/wrappers/PaperWrapper";
import CommonTable from "components/tables/CommonTable";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import TableHeading from "components/headings/TableHeading";
import Map from "features/app-taxi-ranking/map/Map";
import { useSelector } from "react-redux";
import { CLASS_INCIDENTS, CLASS_TAXIES, CLASS_EMBARK_PASSENGERS } from "constants/class.constants";
import { executeGetDetectionsByLocationsTaxiRequest } from "api/common/detections.api";
import { Link } from "react-router-dom";
import HomeAllLocationStatisticsCards from "features/app-taxi-ranking/statistics/HomeAllLocationStatisticsCards";
import { useAppDispatch } from "store";
import { buildLocationAddressStr } from "utils/build-location-str";
import { Grid, MenuItem, SelectChangeEvent } from "@mui/material";
import { Helmet } from "react-helmet";
import { AppTitlePrefix } from "constants/app.constants";
import IotVisionSelectFiled from "components/common/IotVisionSelectField";
import { executeGetIncidentsByLocationsTaxiRequest } from "api/app-taxi-ranking/taxi-incidents.api";
import { DetectionsByLocationQuery, IByLocation, LocationDetection } from "types";
import IotVisionToolTips from "components/common/IotVisionToolTips";
import HeaderDatePicker from "features/common/date-header/HeaderDatePicker";
import { FetchFunctionParams, makeApiCallWithUpdateTime } from "utils/common";
import { findLocation } from "utils/search-tree";
import { SCOPES } from "enums/scopes.enum";
import useDataFilterByScope from "hooks/useDataFilterByScope";
import useHasScope from "hooks/useHasScope";
import NswLogo from "../../../assets/images/nsw-logo.png";

export interface ITopLocationParams {
    fromTime: string;
    toTime: string;
    classification?: string;
    level: number;
    locationId?: string;
}

const options = [
    {
        scope: "",
        value: CLASS_EMBARK_PASSENGERS,
        title: "Highest Passengers",
    },
    {
        scope: SCOPES.READ_INCIDENTS,
        value: CLASS_INCIDENTS,
        title: "Highest Incidents",
    },
    {
        scope: "",
        value: CLASS_TAXIES,
        title: "Highest Taxis",
    },
];

const DashBoardPage = (): JSX.Element => {
    const hasReadIncidentsScope = useHasScope(SCOPES.READ_INCIDENTS);
    const detectionsAbortControllerRef = useRef<AbortController | null>(null);
    const incidentsAbortControllerRef = useRef<AbortController | null>(null);
    const [gridData, setGridData] = useState<LocationDetection[]>([]);
    const { startTime, endTime, selectedRange } = useSelector((state) => state.time);
    const [filterClass, setFilterClass] = useState<string>(CLASS_EMBARK_PASSENGERS);
    const { locations } = useSelector((state) => state.location);
    const { locationsTree } = useSelector((state) => state.newLocation);
    const { appCode } = useSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const filteredDataArray = useDataFilterByScope([SCOPES.READ_INCIDENTS], options);

    const columns: GridColumns = [
        {
            field: "locationName",
            headerName: "Location",
            flex: 2,
            renderCell: (params: GridRenderCellParams) => (
                <IotVisionToolTips title={params.row.locationName} arrow>
                    <span> {params.row.locationName}</span>
                </IotVisionToolTips>
            ),
        },
        {
            field: "groupName",
            headerName: "LGA",
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <IotVisionToolTips title={params.row.groupName} arrow>
                    <span> {params.row.groupName}</span>
                </IotVisionToolTips>
            ),
        },
        {
            field: "location",
            headerName: "",
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <Link to={`/${appCode}/home/location/${params.row.id}`}>{params.value}</Link>
            ),
            align: "right",
        },
    ];

    const pageSize = 5;

    const rowsPerPageOptions = [5, 10];
    const hideFooterPagination = true;

    const fetchData = async ({ startTime, endTime }: FetchFunctionParams) => {
        if (filterClass === CLASS_INCIDENTS && hasReadIncidentsScope) {
            await getIncidentsByLocationsDetections({
                fromTime: startTime,
                toTime: endTime,
                level: -1,
            });
        } else {
            await getDetectionsByLocationsDetections({
                fromTime: startTime,
                toTime: endTime,
                classification: filterClass,
            });
        }
    };

    async function getDetectionsByLocationsDetections(data?: DetectionsByLocationQuery) {
        try {
            detectionsAbortControllerRef.current = new AbortController();
            const { signal } = detectionsAbortControllerRef.current;
            const response = await executeGetDetectionsByLocationsTaxiRequest(data, { signal });
            if (response && Array.isArray(response)) {
                processGridData(response);
            }
        } catch (e) {}
    }

    async function getIncidentsByLocationsDetections(data?: ITopLocationParams) {
        try {
            incidentsAbortControllerRef.current = new AbortController();
            const { signal } = incidentsAbortControllerRef.current;
            const response = await executeGetIncidentsByLocationsTaxiRequest(data, { signal });
            if (response && Array.isArray(response)) {
                processGridData(response);
            }
        } catch (e) {}
    }

    function processGridData(responseData: IByLocation[]) {
        const processedLocationDetections: LocationDetection[] = [];
        responseData
            .sort((a, b) => b.count - a.count)
            .slice(0, 5)
            .forEach((detection) => {
                const location = findLocation(locationsTree, detection.locationId);
                const group = findLocation(locationsTree, location?.parentId ?? "");
                if (location) {
                    processedLocationDetections.push({
                        ...location,
                        locationName: location?.name,
                        address: buildLocationAddressStr(location),
                        location: "View Location",
                        groupName: group?.name,
                    });
                }
            });
        setGridData(processedLocationDetections);
    }

    const handleChange = (v: SelectChangeEvent<string>) => {
        setFilterClass(v.target.value);
    };

    useEffect(() => {
        makeApiCallWithUpdateTime(selectedRange, { startTime, endTime }, fetchData, dispatch);
        return () => {
            if (filterClass === CLASS_INCIDENTS) {
                incidentsAbortControllerRef.current?.abort();
            } else {
                detectionsAbortControllerRef.current?.abort();
            }
        };
    }, [startTime, endTime, filterClass, locations]);

    return (
        <>
            <Helmet>
                <title>{AppTitlePrefix} | Dashboard</title>
                <link rel="icon" id="tr-dashboard" type="image/png" href={NswLogo} sizes="32x32" />
            </Helmet>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
                    <SectionHeading heading="Summary of All Locations" icon={<AiFillAppstore className="icon" />} />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                    <div style={{ maxWidth: "300px", marginLeft: "auto" }}>
                        <HeaderDatePicker />
                    </div>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <HomeAllLocationStatisticsCards />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <PaperWrapper>
                        <Map />
                    </PaperWrapper>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <PaperWrapper>
                        <Grid container spacing={1}>
                            <Grid item xs={9} sm={9} md={8} lg={9} xl={10}>
                                <TableHeading heading="Top Locations" />
                            </Grid>
                            <Grid item xs={3} sm={3} md={4} lg={3} xl={2}>
                                <IotVisionSelectFiled fullWidth value={filterClass} onChange={handleChange}>
                                    {filteredDataArray?.map((option: { value: string; title: string }) => {
                                        return (
                                            <MenuItem key={option?.value} value={option?.value}>
                                                {option?.title}
                                            </MenuItem>
                                        );
                                    })}
                                </IotVisionSelectFiled>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <CommonTable
                                    columns={columns}
                                    rows={gridData}
                                    pageSize={pageSize}
                                    rowsPerPageOptions={rowsPerPageOptions}
                                    hideFooterPagination={hideFooterPagination}
                                />
                            </Grid>
                        </Grid>
                    </PaperWrapper>
                </Grid>
            </Grid>
        </>
    );
};

export default DashBoardPage;
