import { CircularProgress, Grid, styled, Typography } from "@mui/material";
import FullScreenDialog from "components/dialogs/FullScreenDialog";
import { CameraPosition } from "enums/camera-position";
import { formatToReadableDate } from "utils/date-format";
import ExpandIcon from "components/icons/ExpandIcon";
import { ReactElement } from "react";
import PreviewImage from "./PreviewImage";

const customFullScreenDialogStyles = {
    "& .MuiPaper-root": {
        padding: "0",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
        backdropFilter: "blur(25px)",
    },
    "& .MuiBox-root": {
        height: "unset",
    },
    "& .MuiButtonBase-root": {
        zIndex: 2,
        color: "white",
        backgroundColor: "gray",
    },
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        "& span": {
            width: "100%",
        },
        "& span:last-child": {
            width: "100%",
            textAlign: "right",
        },
    },
}));

const StyledGridImageWrapper = styled(Grid)(({ theme }) => ({
    display: "fex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "13vw",
    height: "100%",
    [theme.breakpoints.down("md")]: {
        minHeight: "10vw",
    },
}));

const StyledDeviceName = styled(Typography)(({ theme }) => ({
    color: "#21315b",
    [theme.breakpoints.up("xs")]: {
        fontSize: "0.7rem",
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "0.8rem",
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "1rem",
    },
}));

const StyledCameraPosition = styled(Typography)(({ theme }) => ({
    color: "#21315b",
    [theme.breakpoints.up("xs")]: {
        fontSize: "0.5rem",
        lineHeight: 2,
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "0.65rem",
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "0.8rem",
        lineHeight: 2.75,
    },
}));

const StyledModifiedDate = styled(Typography)(({ theme }) => ({
    color: "#21315b",
    [theme.breakpoints.up("xs")]: {
        fontSize: "0.5rem",
        lineHeight: 2,
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "0.6rem",
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "0.7rem",
        lineHeight: 2.75,
    },
}));

export interface DevicePositionProps {
    deviceName: string;
    cameraPosition: CameraPosition;
    imageLoaded: boolean;
    modalShow: boolean;
    isUpdateRequested?: boolean;
    lastModified?: string;
    url?: string;
    handleFullScreen: (type: CameraPosition, state: boolean) => void;
    handleImageLoading: (type: CameraPosition, state: boolean) => void;
}

const DevicePosition = ({
    cameraPosition,
    deviceName,
    lastModified,
    url,
    modalShow,
    imageLoaded,
    isUpdateRequested,
    handleFullScreen,
    handleImageLoading,
}: DevicePositionProps): ReactElement => {
    return (
        <>
            <Grid container direction="row" justifyContent="space-between">
                <Grid display="flex" alignItems="center">
                    <StyledDeviceName>{deviceName}</StyledDeviceName>
                </Grid>
                <StyledGrid display="flex" alignItems="center" justifyContent="space-between" width="100%">
                    <StyledCameraPosition variant="button" fontWeight={600}>
                        {cameraPosition}
                    </StyledCameraPosition>
                    <StyledModifiedDate variant="overline">
                        {lastModified ? formatToReadableDate(new Date(lastModified)) : "N/A"}
                    </StyledModifiedDate>
                </StyledGrid>
            </Grid>
            <StyledGridImageWrapper position="relative" flexGrow={imageLoaded ? 1 : undefined} container paddingTop={1}>
                <FullScreenDialog
                    modalShow={modalShow}
                    onClose={() => handleFullScreen(cameraPosition, false)}
                    customStyles={customFullScreenDialogStyles}
                    childComponent={
                        <PreviewImage
                            styles={{ backgroundColor: "transparent", backdropFilter: "blur(50px)", boxShadow: "none" }}
                            alt={`${cameraPosition} image`}
                            src={url}
                        />
                    }
                />
                {!isUpdateRequested && url && imageLoaded && (
                    <ExpandIcon onClick={() => handleFullScreen(cameraPosition, true)} />
                )}
                {(isUpdateRequested || !imageLoaded) && <CircularProgress color="primary" />}
                {!isUpdateRequested && (
                    <PreviewImage
                        alt={`${cameraPosition} image`}
                        notifyLoaded={(status) => handleImageLoading(cameraPosition, status)}
                        src={url}
                        styles={{
                            display: imageLoaded ? "flex" : "none",
                        }}
                    />
                )}
            </StyledGridImageWrapper>
        </>
    );
};

export default DevicePosition;
