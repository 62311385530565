
import { DEFAULT_DATE_FORMAT } from "constants/time.constants";
import { format } from "date-fns";

export const formatDate = (d: Date | string, options: Intl.DateTimeFormatOptions, locale?: string): string => {
    let date;
    if (!locale) {
        locale = "en-AU";
    }
    if (typeof d === "string") {
        date = new Date(d);
    }
    return new Intl.DateTimeFormat(locale, options).format(date);
};

export default formatDate;

/**
 * Helper function to format date to readable manner
 * @param date - The date to be formatted (Date object or timestamp)
 * @param formatStr - Optional. The format string for date formatting. Defaults to 'dd/MM/yyyy hh:mm aa'
 * @returns The formatted date string
 */
export const formatToReadableDate = (date: Date | number, formatStr: string = DEFAULT_DATE_FORMAT): string =>
  format(date, formatStr);