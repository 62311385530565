import { IconButton, styled } from "@mui/material";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { FC } from "react";

const StyledIconButton = styled(IconButton)({
    backgroundColor: "#f2f2f2",
    color: "#7a7a7a",
    padding: 4,
    borderRadius: "3px",
    position: "absolute",
    top: 10,
    right: 3,
    zIndex: 1,
});

interface ExpandIconProps {
    onClick: () => void;
}

const ExpandIcon: FC<ExpandIconProps> = ({ onClick }) => (
    <StyledIconButton onClick={onClick}>
        <OpenInFullIcon sx={{ fontSize: 15 }} />
    </StyledIconButton>
);

export default ExpandIcon;
