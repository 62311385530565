import React, { memo, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { executeGetDetectionsByLocationsRequest } from "api/common/detections.api";
import circleGreen from "assets/images/indicators/circle_green.png";
import circleRed from "assets/images/indicators/circle_red.png";
import circleYellow from "assets/images/indicators/circle_yellow.png";
import { CLASS_CYCLE } from "constants/class.constants";
import { useAppDispatch } from "store";
import { checkLocationIdChanged, FetchFunctionParams, makeApiCallWithUpdateTime } from "utils/common";
import { useLocation, useParams } from "react-router-dom";
import { findLocation } from "utils/search-tree";
import { LocationType } from "enums/location.enums";
import { Classification } from "enums/classifications.enum";
import { APPLICATION_CYCLEWAYS_BIKE_RACKS } from "constants/app.constants";
import { DetectionsByLocationQuery } from "types";
import MarkerClustererCycleways from "./MarkerClusterer";
import MainMap from "../../../components/map/Map";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
interface DetectionByLocation {
    locationId: string;
    classification: string;
    count: string;
}

const Map = (props: { id?: string }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id } = props;
    const { groupId, locationId } = useParams();
    const { pathname } = useLocation();
    const dispatch = useAppDispatch();
    const { startTime, endTime, selectedRange } = useSelector((state) => state.time);
    const { leafLocations } = useSelector((state) => state.newLocation);
    const [processedLocations, setProcessedLocations] = React.useState<any[]>([]);
    const [locationsArr, setLocationsArr] = React.useState<any[]>([]);
    const [locationsResponse, setLocationsResponse] = React.useState<any[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const { locationsTree, locations } = useSelector((state) => state.newLocation);
    const locationsAbortControllerRef = useRef<AbortController | null>(null);
    const containerStyle = {
        height: "430px",
        backgroundColor: "#000",
    };
    const clusterStyles = [
        { height: 89, width: 89, url: circleGreen },
        { height: 89, width: 89, url: circleYellow },
        { height: 89, width: 89, url: circleRed },
    ];

    async function getDetectionsByLocations(data?: DetectionsByLocationQuery) {
        setIsLoading(true);
        try {
            locationsAbortControllerRef.current = new AbortController();
            const { signal } = locationsAbortControllerRef.current;
            const response = await executeGetDetectionsByLocationsRequest(data, {
                signal,
                disableNotification: checkLocationIdChanged(locationId, locations),
            });
            if (response && Array.isArray(response)) {
                setLocationsResponse(response);
                if (locationId === null || locationId === undefined) {
                    mapLocations(leafLocations, response);
                } else {
                    const node = findLocation(locationsTree, locationId);
                    if (node) {
                        setLocationsArr([]);
                        searchLocations(node);
                    }
                }
            }
            // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
            setIsLoading(false);
        }
    }

    function mapLocations(lst: any[], response: DetectionByLocation[]) {
        const mapped = lst.map((l) => {
            const counts = response.find((i) => i.locationId === l.id);
            return {
                ...l,
                lat: parseFloat(l.latitude),
                lng: parseFloat(l.longitude),
                address: l.address1,
                count: counts?.count || 0,
            };
        });
        setProcessedLocations(mapped);
    }

    function searchTree(element: any, id: string): any {
        if (element.id === id) {
            return element;
        }
        if (element.children?.length) {
            let i;
            let result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                result = searchTree(element.children[i], id);
            }
            return result;
        }
        return null;
    }

    function searchLocations(element: any): any {
        if (element && element.type === LocationType.LOCATION) {
            setLocationsArr((locationsArr) => [...locationsArr, element]);
        } else if (element.children?.length) {
            let i;
            for (i = 0; i < element.children.length; i++) {
                searchLocations(element.children[i]);
            }
        }
    }
    const markerClustererCalculator = (markers: Array<any>): any => {
        if (markers.find((marker) => marker.count > 6)) return { index: 3, text: markers.length };
        if (markers.find((marker) => marker.count > 2)) return { index: 2, text: markers.length };
        return { index: 1, text: markers.length };
    };

    const fetchData = async ({ startTime, endTime }: FetchFunctionParams) => {
        if (groupId) {
            await getDetectionsByLocations({
                classification: checkApplication(`/${APPLICATION_CYCLEWAYS_BIKE_RACKS}`)
                    ? Classification.UNATTENDED_BICYCLE
                    : CLASS_CYCLE,
                fromTime: startTime,
                toTime: endTime,
            });
        } else if (locationId) {
            await getDetectionsByLocations({
                classification: checkApplication(`/${APPLICATION_CYCLEWAYS_BIKE_RACKS}`)
                    ? Classification.UNATTENDED_BICYCLE
                    : CLASS_CYCLE,
                locationId,
                fromTime: startTime,
                toTime: endTime,
            });
        } else {
            await getDetectionsByLocations({
                classification: checkApplication(`/${APPLICATION_CYCLEWAYS_BIKE_RACKS}`)
                    ? Classification.UNATTENDED_BICYCLE
                    : CLASS_CYCLE,
                fromTime: startTime,
                toTime: endTime,
            });
        }
    };

    useEffect(() => {
        makeApiCallWithUpdateTime(selectedRange, { startTime, endTime }, fetchData, dispatch);
        return () => {
            locationsAbortControllerRef.current?.abort();
        };
    }, [groupId, locationId, startTime, endTime]);

    useEffect(() => {
        if (locationsArr === null) {
            setProcessedLocations([]);
        } else {
            mapLocations(locationsArr, locationsResponse);
        }
    }, [locationsArr]);

    const checkApplication = (application: string) => {
        return pathname.includes(application);
    }

    return (
        <MainMap containerStyle={containerStyle} locations={[processedLocations]}>
            <MarkerClustererCycleways
                markerClustererCalculator={markerClustererCalculator}
                clusterStyles={clusterStyles}
                locations={processedLocations}
            />
        </MainMap>
    );
};

export default memo(Map);
