import { Box, CircularProgress, BoxProps } from "@mui/material";
import { CSSProperties } from "@mui/styles";
import React from "react";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
interface LoadingWrapperProps extends BoxProps {
    isLoading: boolean;
    children?: any;
    loaderStyles?: CSSProperties;
}

export const LoadingWrapper: React.FC<LoadingWrapperProps> = ({ isLoading, loaderStyles, children, ...rest }) => {
    if (isLoading) {
        return (
            <Box {...rest} display="flex" justifyContent="center" alignItems="center">
                <CircularProgress style={loaderStyles} color="primary" />
            </Box>
        );
    }
    return children;
};
