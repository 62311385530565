import CameraPositionLayout from "layouts/app-taxi-ranking/camera-position";
import { ProtectedLayout } from "layouts/common/general";
import CameraPosition from "pages/common/camera-position";
import WithScopes from "components/common/WithScopes";
import { SCOPES } from "enums/scopes.enum";
import NotFound from "pages/common/errors/not-found/NotFound";
import Forbidden from "pages/common/errors/forbidden/Forbidden";

const root = {
    path: "admin-camera-comparison",
    element: (
        <ProtectedLayout>
            <WithScopes filterScopes={[SCOPES.VIEW_CAMERA_POSITION]}>
                <CameraPositionLayout />
            </WithScopes>
        </ProtectedLayout>
    ),
    children: [
        {
            path: "",
            element: <CameraPosition />,
        },
        {
            path: ":locationId",
            element: <CameraPosition />,
        },
        {
            path: "location/:locationId",
            element: <CameraPosition />,
        },
        {
            path: "page-not-found",
            element: <NotFound />,
        },
        {
            path: "forbidden",
            element: <Forbidden />,
        },
    ],
};
export default root;
