import { Grid, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { DevicePositionDetails } from "types/camera-position/position";
import { CameraPosition } from "enums/camera-position";
import DevicePosition from "./DevicePosition";
import UpdatingButton from "./UpdatingButton";

const StyledGridWrapper = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
        justifyContent: "center",
        alignItems: "stretch",
        flexDirection: "column",
        "& div": {
            width: "100%",
        },
    },
}));

export interface DevicesProps {
    device: DevicePositionDetails;
    isDataUpdating: boolean;
    updateDevicePosition: (deviceId: string) => Promise<void>;
}

function Devices({ device, isDataUpdating, updateDevicePosition }: DevicesProps) {
    const { current, reference, deviceName, deviceId } = device || {};
    const [imageFullScreen, setImageFullScreen] = useState({ reference: false, current: false });
    const [imageLoaded, setImageLoaded] = useState({ reference: false, current: false });
    const [isUpdateRequested, setIsUpdateRequested] = useState(false);

    useEffect(() => {
        if (!isDataUpdating) setIsUpdateRequested(false);
    }, [isDataUpdating]);

    const handleFullScreen = (type: CameraPosition, state: boolean) => {
        if (type === CameraPosition.REFERENCE) setImageFullScreen((prev) => ({ ...prev, reference: state }));
        if (type === CameraPosition.CURRENT) setImageFullScreen((prev) => ({ ...prev, current: state }));
    };

    const handleImageLoading = (type: CameraPosition, state: boolean) => {
        if (type === CameraPosition.REFERENCE) setImageLoaded((prev) => ({ ...prev, reference: state }));
        if (type === CameraPosition.CURRENT) setImageLoaded((prev) => ({ ...prev, current: state }));
    };

    const handleButtonClick = async (deviceId: string) => {
        setImageLoaded((prev) => ({ ...prev, reference: false }));
        setIsUpdateRequested(true);
        await updateDevicePosition(deviceId);
    };

    return (
        <StyledGridWrapper container ml={0} mt={2} sx={{ minHeight: "10vw" }}>
            <Grid
                item
                display={imageLoaded ? "flex" : undefined}
                flexDirection={imageLoaded ? "column" : undefined}
                width="100%"
                xs={12}
                md={4.75}
            >
                <DevicePosition
                    cameraPosition={CameraPosition.REFERENCE}
                    deviceName={deviceName}
                    modalShow={imageFullScreen.reference}
                    imageLoaded={imageLoaded.reference}
                    lastModified={reference?.lastModified}
                    url={reference?.url}
                    handleFullScreen={handleFullScreen}
                    handleImageLoading={handleImageLoading}
                    isUpdateRequested={isUpdateRequested}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={2.5}
                paddingTop={{ xs: "1rem", md: "6rem" }}
                paddingBottom={{ xs: "1rem", md: "0rem" }}
                alignItems="stretch"
            >
                <UpdatingButton
                    buttonText="Set Current as Reference"
                    isButtonDisabled={
                        (!imageLoaded?.reference && !!reference?.url) ||
                        !imageLoaded?.current ||
                        !current?.url ||
                        (isDataUpdating && isUpdateRequested)
                    }
                    handleButtonClick={() => handleButtonClick(deviceId)}
                    currentUrl={current?.url}
                />
            </Grid>
            <Grid
                item
                display={imageLoaded ? "flex" : undefined}
                flexDirection={imageLoaded ? "column" : undefined}
                xs={12}
                md={4.75}
            >
                <DevicePosition
                    cameraPosition={CameraPosition.CURRENT}
                    deviceName={deviceName}
                    modalShow={imageFullScreen.current}
                    imageLoaded={imageLoaded.current}
                    lastModified={current?.lastModified}
                    url={current?.url}
                    handleFullScreen={handleFullScreen}
                    handleImageLoading={handleImageLoading}
                />
            </Grid>
        </StyledGridWrapper>
    );
}

export default Devices;
