import { Button, Grid, Menu, styled, SxProps, Theme, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import EventIcon from "@mui/icons-material/Event";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { MobileDateTimePicker } from "@mui/lab";
import IotVisionTextFiled from "components/common/IotVisionTextFiled";
import IotVisionButton from "./IotVisionButton";

export interface DatePickerValue {
    fromTime: string;
    toTime: string;
    range: string;
}

export enum DisableReason {
    PRE_FILTER = "preFilter"
}

export interface CommonDateRangePickerProps {
    customIcon?: React.ReactNode;
    sx?: SxProps<Theme>;
    value: DatePickerValue;
    onDatePickerClear: () => void;
    onPickerMenuClose?: () => void;
    onDatePickerApply: (startDate: string, endDate: string, range: string) => void;
    isValidFunction?: (
        startDateTime: string,
        endDateTime: string,
        callBackMessage: Dispatch<SetStateAction<string>>,
    ) => void;
    buttonText: string;
    shouldDisplayPredefinedValues?: boolean;
    customPlaceHolder?: string;
    disableReason?: DisableReason | null;
}

export enum pickerRanges {
    DateRange = "Date Range",
    LastHour = "Last hour",
    Last12Hours = "Last 12 hours",
    Last2Hours = "Last 2 hours",
    Last24Hours = "Last 24 hours",
    Last4hours = "Last 4 hours",
    Custom = "Custom",
}

const defaultIsValidFunction = (
    startDateTime: string,
    endDateTime: string,
    setWarningMessage: Dispatch<SetStateAction<string>>,
) => {
    const isSameDates = new Date(endDateTime).getTime() === new Date(startDateTime).getTime();

    const isBeforeEndDate = new Date(endDateTime).getTime() < new Date(startDateTime).getTime();

    const isFutureTimeSelected =
        new Date(startDateTime).getTime() > new Date().getTime() ||
        new Date(endDateTime).getTime() > new Date().getTime();
    if (isSameDates) {
        setWarningMessage("Start date and end date must be different");
    } else if (isFutureTimeSelected) {
        setWarningMessage("Date Entered Exceeds Todays Date");
    } else if (isBeforeEndDate) {
        setWarningMessage("Start date must be before end date");
    } else {
        setWarningMessage("");
    }
};

const StyledWarningIcon = styled(WarningAmberIcon)(() => ({
    color: "gray",
    cursor: "pointer",
}));

const ReasonTypography = styled(Typography)(() => ({
    fontSize: "12px",
}));

const WarningTypography = styled(Typography)(() => ({
    color: "#21315b",
}));

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
const CommonDateRangePicker: React.FC<CommonDateRangePickerProps> = ({
    customIcon,
    sx,
    value,
    onPickerMenuClose,
    onDatePickerClear,
    onDatePickerApply,
    isValidFunction = defaultIsValidFunction,
    buttonText,
    shouldDisplayPredefinedValues = true,
    customPlaceHolder,
    disableReason = null,
}) => {
    const [endDateTime, setEndDateTime] = useState<any | null>(null);
    const [startDateTime, setStartDateTime] = useState<any | null>(null);
    const [startDateTimePicker, setStartDateTimePicker] = useState<any>(null);
    const [endDateTimePicker, setEndDateTimePicker] = useState<any>(null);
    const [disableWarning, setDisableWarning] = useState<string | null>(null);
    const [selectedRangeFilter, setSelectedRangeFilter] = useState<string>(pickerRanges.DateRange);
    const [finalFilter, setFinalFilter] = useState<string>(pickerRanges.DateRange);

    const [warningMessage, setWarningMessage] = useState<string>("");

    const getBorderColor = (button: pickerRanges) => {
        return selectedRangeFilter === button ? "#21315b" : "#ebeff8";
    };

    const handleClose = () => {
        setAnchorEl(null);
        onDatePickerClear();
        setWarningMessage("");
        setFinalFilter(value.range);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    function handleBtnClick(hours: number, label: string) {
        const end = new Date();
        end.setSeconds(0, 0);
        setEndDateTime(end.toISOString());
        setEndDateTimePicker(end);
        const dt = new Date();
        dt.setHours(dt.getHours() - hours);
        dt.setSeconds(0, 0);
        const start = dt.toISOString();
        setStartDateTime(start);
        setStartDateTimePicker(dt);
        setSelectedRangeFilter(label);
    }

    function handleApply() {
        setAnchorEl(null);
        setFinalFilter(selectedRangeFilter);
        onDatePickerApply(startDateTime, endDateTime, selectedRangeFilter);
    }

    function onStartDateTimeChange(newValue: Date | null): void {
        if (newValue) {
            setStartDateTimePicker(newValue);
            setStartDateTime(new Date(new Date(newValue).setSeconds(0, 0)).toISOString());
            setSelectedRangeFilter("Custom");
        }
    }

    function onEndDateTimeChange(newValue: Date | null): void {
        if (newValue) {
            setEndDateTimePicker(newValue);
            setEndDateTime(new Date(new Date(newValue).setSeconds(0, 0)).toISOString());
            setSelectedRangeFilter("Custom");
        }
    }

    const handleHelperText = (reason: DisableReason | null) => {
        switch (reason) {
            case DisableReason.PRE_FILTER:
                setDisableWarning(
                    `Applied ${reason} disables date filtering. Selected values will be ignored.`,
                );
                break;
            default:
                setDisableWarning(null);
        }
    }

    useEffect(() => {
        isValidFunction(startDateTime, endDateTime, setWarningMessage);
    }, [startDateTime, endDateTime]);

    useEffect(() => {
        if (value?.fromTime && value?.toTime && value?.range) {
            setStartDateTimePicker(value?.fromTime);
            setEndDateTimePicker(value?.toTime);
            setStartDateTime(new Date(value?.fromTime).toISOString());
            setEndDateTime(new Date(value?.toTime).toISOString());
            setSelectedRangeFilter(value?.range);
            setFinalFilter(value?.range);
        } else {
            setStartDateTimePicker(null);
            setEndDateTimePicker(null);
            setStartDateTime("");
            setEndDateTime("");
            setSelectedRangeFilter(customPlaceHolder || pickerRanges.DateRange);
            setFinalFilter(customPlaceHolder || pickerRanges.DateRange);
        }
    }, [value]);

    useEffect(() => {
        handleHelperText(disableReason);
    }, [disableReason]);

    return (
        <div>
            <IotVisionTextFiled
                {...(sx ? { sx } : {})}
                fullWidth
                InputProps={{
                    readOnly: true,
                    endAdornment: disableWarning ? (
                        <StyledWarningIcon />
                    ) : (
                        customIcon || <EventIcon sx={{ color: "gray" }} />
                    ),
                }}
                disabled={!!disableReason}
                placeholder={finalFilter}
                onClick={(event: any) => handleClick(event)}
            />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => {
                    setAnchorEl(null);
                    if (onPickerMenuClose) {
                        onPickerMenuClose();
                    }
                }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,

                        "&:before": {
                            content: '""',
                            position: "absolute",
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                {disableWarning && (
                    <div className="DropDownOuter-wrapper">
                        <Grid container item xs={12} sm={12} md={12} lg={12}>
                            <WarningTypography>Warning</WarningTypography>
                        </Grid>
                        <Grid container item xs={12} sm={12} md={12} lg={12}>
                            {disableWarning && <ReasonTypography>{disableWarning}</ReasonTypography>}
                        </Grid>
                    </div>
                )}
                {!disableWarning && (
                    <div className="DropDownOuter-wrapper">
                        <Grid container item>
                            <Grid container item xs={12} sm={12} md={12} lg={12}>
                                <span className="topDropDwn_head">Select time range</span>
                            </Grid>
                            {shouldDisplayPredefinedValues && (
                                <>
                                    <Grid container item xs={12} sm={6} md={6} lg={6} className="headerDroplineSpace">
                                        <div className="DropDownBtn-wrapper">
                                            <Button
                                                sx={{
                                                    border: 1,
                                                    borderColor: getBorderColor(pickerRanges.LastHour),
                                                    "&:hover": {
                                                        border: 1,
                                                        borderColor: getBorderColor(pickerRanges.LastHour),
                                                    },
                                                }}
                                                variant="outlined"
                                                size="small"
                                                onClick={() => handleBtnClick(1, pickerRanges.LastHour)}
                                            >
                                                {pickerRanges.LastHour}
                                            </Button>
                                        </div>
                                    </Grid>
                                    <Grid container item xs={12} sm={6} md={6} lg={6} className="headerDroplineSpace">
                                        <div className="DropDownBtn-wrapper">
                                            <Button
                                                sx={{
                                                    border: 1,
                                                    borderColor: getBorderColor(pickerRanges.Last12Hours),
                                                    "&:hover": {
                                                        border: 1,
                                                        borderColor: getBorderColor(pickerRanges.Last12Hours),
                                                    },
                                                }}
                                                variant="outlined"
                                                size="small"
                                                onClick={() => handleBtnClick(12, pickerRanges.Last12Hours)}
                                            >
                                                {pickerRanges.Last12Hours}
                                            </Button>
                                        </div>
                                    </Grid>
                                    <Grid container item xs={12} sm={6} md={6} lg={6} className="headerDroplineSpace">
                                        <div className="DropDownBtn-wrapper">
                                            <Button
                                                sx={{
                                                    border: 1,
                                                    borderColor: getBorderColor(pickerRanges.Last2Hours),
                                                    "&:hover": {
                                                        border: 1,
                                                        borderColor: getBorderColor(pickerRanges.Last2Hours),
                                                    },
                                                }}
                                                variant="outlined"
                                                size="small"
                                                onClick={() => handleBtnClick(2, pickerRanges.Last2Hours)}
                                            >
                                                {pickerRanges.Last2Hours}
                                            </Button>
                                        </div>
                                    </Grid>
                                    <Grid container item xs={12} sm={6} md={6} lg={6} className="headerDroplineSpace">
                                        <div className="DropDownBtn-wrapper">
                                            <Button
                                                sx={{
                                                    border: 1,
                                                    borderColor: getBorderColor(pickerRanges.Last24Hours),
                                                    "&:hover": {
                                                        border: 1,
                                                        borderColor: getBorderColor(pickerRanges.Last24Hours),
                                                    },
                                                }}
                                                variant="outlined"
                                                size="small"
                                                onClick={() => handleBtnClick(24, pickerRanges.Last24Hours)}
                                            >
                                                {pickerRanges.Last24Hours}
                                            </Button>
                                        </div>
                                    </Grid>
                                    <Grid container item xs={12} sm={6} md={6} lg={6} className="headerDroplineSpace">
                                        <div className="DropDownBtn-wrapper">
                                            <Button
                                                sx={{
                                                    border: 1,
                                                    borderColor: getBorderColor(pickerRanges.Last4hours),
                                                    "&:hover": {
                                                        border: 1,
                                                        borderColor: getBorderColor(pickerRanges.Last4hours),
                                                    },
                                                }}
                                                variant="outlined"
                                                size="small"
                                                onClick={() => handleBtnClick(4, pickerRanges.Last4hours)}
                                            >
                                                {pickerRanges.Last4hours}
                                            </Button>
                                        </div>
                                    </Grid>
                                    <Grid container item xs={12} sm={6} md={6} lg={6} className="headerDroplineSpace">
                                        <div className="DropDownBtn-wrapper">
                                            <Button
                                                sx={{
                                                    border: 1,
                                                    borderColor: getBorderColor(pickerRanges.Custom),
                                                    "&:hover": {
                                                        border: 1,
                                                        borderColor: getBorderColor(pickerRanges.Custom),
                                                    },
                                                }}
                                                variant="outlined"
                                                size="small"
                                                onClick={() => {
                                                    setStartDateTimePicker(null);
                                                    setEndDateTimePicker(null);
                                                    setStartDateTime("");
                                                    setEndDateTime("");
                                                    setSelectedRangeFilter("Custom");
                                                }}
                                            >
                                                {pickerRanges.Custom}
                                            </Button>
                                        </div>
                                    </Grid>
                                </>
                            )
                        }
                        </Grid>
                        <Grid container item xs={12} sm={12} md={12} lg={12} className="headerDroplineSpace">
                            <div className="DropDownInput-wrapper">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <MobileDateTimePicker
                                        maxDateTime={endDateTimePicker as Date}
                                        disableFuture
                                        renderInput={(props) => (
                                            <IotVisionTextFiled
                                                sx={
                                                    warningMessage
                                                        ? {
                                                              "& .MuiOutlinedInput-root": {
                                                                  "& fieldset": {
                                                                      borderColor: "red",
                                                                  },
                                                                  "&.Mui-focused fieldset": {
                                                                      borderColor: "red",
                                                                  },
                                                              },
                                                          }
                                                        : {}
                                                }
                                                placeholder="Start date and time"
                                                {...props}
                                            />
                                        )}
                                        value={startDateTimePicker}
                                        onChange={(newValue) => {
                                            onStartDateTimeChange(newValue);
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                        </Grid>

                        <Grid container item xs={12} sm={12} md={12} lg={12} className="headerDroplineSpace">
                            <div className="DropDownInput-wrapper">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <MobileDateTimePicker
                                        disableFuture
                                        maxDateTime={new Date()}
                                        minDateTime={startDateTimePicker as Date}
                                        renderInput={(props) => (
                                            <IotVisionTextFiled
                                                sx={
                                                    warningMessage
                                                        ? {
                                                              "& .MuiOutlinedInput-root": {
                                                                  "& fieldset": {
                                                                      borderColor: "red",
                                                                  },
                                                                  "&.Mui-focused fieldset": {
                                                                      borderColor: "red",
                                                                  },
                                                              },
                                                          }
                                                        : {}
                                                }
                                                placeholder="End date and time"
                                                {...props}
                                            />
                                        )}
                                        value={endDateTimePicker}
                                        onChange={(newValue) => {
                                            onEndDateTimeChange(newValue);
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                        </Grid>
                        {warningMessage && (
                            <Grid item xs={12}>
                                <Typography fontSize="14px" marginLeft="14px" color="red">
                                    {warningMessage}
                                </Typography>
                            </Grid>
                        )}
                    </div>
                )}
                <div className="dropDwnBottom-wrapper">
                    <Grid container spacing={1} item xs={12} sm={12} md={12} lg={12}>
                        <Grid container item xs={12} sm={12} md={6} lg={6}>
                            <IotVisionButton
                                onClick={handleApply}
                                {...(Boolean(warningMessage) ||
                                !(startDateTimePicker && endDateTimePicker) ||
                                disableWarning
                                    ? {
                                          sx: {
                                              backgroundColor: "disableColor.main",
                                              color: "disableColor.lightOverRide",
                                          },
                                          disabled: true,
                                      }
                                    : {})}
                            >
                                Apply
                            </IotVisionButton>
                        </Grid>
                        <Grid container item xs={12} sm={12} md={6} lg={6}>
                            <IotVisionButton
                                sx={{
                                    backgroundColor: "white",
                                    color: "primary.main",
                                    "&:hover": {
                                        background: "white",
                                    },
                                }}
                                onClick={handleClose}
                            >
                                {buttonText}
                            </IotVisionButton>
                        </Grid>
                    </Grid>
                </div>
            </Menu>
        </div>
    );
};

export default CommonDateRangePicker;
