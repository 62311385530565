import { useEffect, useRef, useState } from "react";
import { Box, Grid } from "@mui/material";
import PaperWrapper from "components/wrappers/PaperWrapper";
import { executeGetDetectionsOvertimeRequest } from "api/common/detections.api";
import { CLASS_CYCLE } from "constants/class.constants";
import { useSelector } from "react-redux";
import LocationDirectionMap from "features/app-cycle-ways/directional-map/LocationDirectionMap";
import { useParams } from "react-router-dom";
import DetectedCyclistsChart from "features/app-cycle-ways/chart/DetectedCyclistsChart";
import HistoricalTrendFilter from "features/app-cycle-ways/chart/HistoricalTrendFilter";
import { DetectionOvertimeResponse, DetectionsOvertimeQuery } from "types";
import TransportationMeanChart from "features/app-cycle-ways/chart/TransportationMeanChart";
import { useAppDispatch } from "store";
import { checkLocationIdChanged, FetchFunctionParams, makeApiCallWithUpdateTime } from "utils/common";

const config = {
    backgroundColor: "rgba(107, 67, 157, 1)",
    borderColor: "rgba(107, 67, 157, 1)",
    borderWidth: 1,
    maintainAspectRatio: true,
    label: "Cyclist Count",
};

export interface CyclistTransport {
    classification?: string;
    fromTime: string;
    toTime: string;
    scaleUnit: string | undefined;
}
const LocationChartLayout = () => {
    const [transportationMeanData, setTransportationMeanData] = useState<DetectionOvertimeResponse[]>([]);
    const [detectedCyclistData, setDetectedCyclistData] = useState<DetectionOvertimeResponse[]>([]);
    const { startTime, endTime, timeType, selectedRange } = useSelector((state) => state.time);
    const { locationId } = useParams();
    const detectedCyclesAbortControllerRef = useRef<AbortController | null>(null);
    const transportationMeanAbortControllerRef = useRef<AbortController | null>(null);
    const { locations } = useSelector((state) => state.newLocation);
    const dispatch = useAppDispatch();

    const fetchData = async ({ startTime, endTime }: FetchFunctionParams) => {
        if (locationId) {
            await Promise.all([
                getDetectedCyclistsData(locationId, {
                    classification: CLASS_CYCLE,
                    fromTime: startTime,
                    toTime: endTime,
                    scaleUnit: timeType,
                }),
                getTransportationMeanData(locationId, {
                    fromTime: startTime,
                    toTime: endTime,
                    scaleUnit: timeType,
                }),
            ]);
        }
    };
    async function getTransportationMeanData(locationId: string, data?: DetectionsOvertimeQuery): Promise<void> {
        try {
            transportationMeanAbortControllerRef.current = new AbortController();
            const { signal } = transportationMeanAbortControllerRef.current;
            const response: DetectionOvertimeResponse[] = await executeGetDetectionsOvertimeRequest(locationId, data, {
                signal,
                disableNotification: checkLocationIdChanged(locationId, locations),
            });
            if (response) setTransportationMeanData(response);
        } catch (e) {
            console.log(e);
        } finally {
            // setLoading(false);
        }
    }

    async function getDetectedCyclistsData(locationId: string, data?: DetectionsOvertimeQuery): Promise<void> {
        try {
            detectedCyclesAbortControllerRef.current = new AbortController();
            const { signal } = detectedCyclesAbortControllerRef.current;
            const response: DetectionOvertimeResponse[] = await executeGetDetectionsOvertimeRequest(locationId, data, {
                signal,
                disableNotification: checkLocationIdChanged(locationId, locations),
            });
            if (response) setDetectedCyclistData(response);
        } catch (e) {
            console.log(e);
        } finally {
            // setLoading(false);
        }
    }

    useEffect(() => {
        makeApiCallWithUpdateTime(selectedRange, { startTime, endTime }, fetchData, dispatch);
        if (locationId) {
            return () => {
                transportationMeanAbortControllerRef.current?.abort();
                detectedCyclesAbortControllerRef.current?.abort();
            };
        }
    }, [startTime, endTime, locationId]);

    return (
        <Grid container spacing={1}>
            <Grid container spacing={1} display="flex">
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    sx={{ display: "flex", flexDirection: "column", flexGrow: 1, height: {xs: "auto", lg: "100%"} }}
                >
                    <DetectedCyclistsChart title="Detected Cyclist Count" data={detectedCyclistData} config={config} />
                    <TransportationMeanChart title="Detected Transportation Means" data={transportationMeanData} />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    sx={{ display: "flex", flexDirection: "column", flexGrow: 1, height: {xs: "50%", lg: "100%"} }}
                >
                    <PaperWrapper
                        cusStyle={{
                            display: "flex",
                            height: "100%",
                            width: "100%",
                            flexBasis: "0px",
                            flexGrow: 1,
                            overflowY: "hidden",
                        }}
                        innerCusStyle={{
                            width: "100%",
                            height : "100%",
                        }}
                    >
                        <span className="chartHearder">Cycle Direction of Travel</span>
                        <Box sx={{ height: "95%", width: "100%" }}>
                            <LocationDirectionMap locationId={locationId} />
                        </Box>
                    </PaperWrapper>
                </Grid>
            </Grid>
            <Grid container xs={12} sx={{ marginTop: {xs: "55%", lg: 0}}}>
                <Grid item xs={12}>
                    <HistoricalTrendFilter />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LocationChartLayout;
