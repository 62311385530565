import { useEffect, useRef, useState } from "react";
import { executeGetDeviceSnapshotsRequest, executeUpdateDeviceSnapshots } from "api/common/camera-position";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { LocationWithCameraPosition } from "types/camera-position/position";
import SectionHeading from "components/headings/SectionHeading";
import { LoadingWrapper } from "components/wrappers/LoadingWrapper";
import { IoGitCompareSharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import { LocationType } from "enums/location.enums";
import { Location } from "reducers/newLocation.reducer";
import { NavigateNotFound } from "constants/paths.constants";
import Locations from "../../../features/common/camera-position/Locations";

const HEADER_CAMERA_POSITION_COMPARISON = "Camera Position Comparison";

function CameraPositionComparer() {
    const getCameraPositionsAbortControllerRef = useRef<AbortController | null>(null);
    const updateCameraPositionAbortControllerRef = useRef<AbortController | null>(null);
    const { locations, leafLocations } = useSelector((state) => state.newLocation);
    const { appCode } = useSelector((state) => state.auth);
    const { locationId } = useParams();
    const isInitialMount = useRef(true);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [cameraPositions, setCameraPositions] = useState<LocationWithCameraPosition[]>([]);
    const [modifiedCameraPositions, setModifiedCameraPositions] = useState<LocationWithCameraPosition[]>([]);

    useEffect(() => {
        setLoading(true);
        (async () => {
            await getCameraPositions();
        })();
        return () => setCameraPositions([]);
    }, []);

    useEffect(() => {
        // Check if it's the initial mount
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            // This will only run on updates, not on the initial mount
            handleLocationSelection(cameraPositions);
        }
    }, [locationId, isUpdating]);

    const handleLocationSelection = (dataset: LocationWithCameraPosition[]) => {
        setLoading(true);
        let selectedLeafLocationList: Location[] = [];
        const selectedLocation = locations?.find((loc) => loc.id === locationId);
        if (!locationId) selectedLeafLocationList = [...leafLocations]?.sort((a, b) => a.name.localeCompare(b.name));
        if (locationId && !selectedLocation)
            navigate(`/${appCode}/admin-camera-comparison/${NavigateNotFound}`, { replace: true });
        else if (selectedLocation)
            switch (selectedLocation.type) {
                case LocationType.REGION:
                    selectedLeafLocationList = locations
                        ?.filter((loc) => loc.type === LocationType.ZONE && loc.parentId === selectedLocation.id)
                        ?.flatMap((zone) => locations?.filter((loc) => loc.parentId === zone.id))
                        ?.sort((a, b) => a.name.localeCompare(b.name));
                    break;
                case LocationType.ZONE:
                    selectedLeafLocationList = locations
                        ?.filter((loc) => loc.parentId === selectedLocation.id)
                        ?.sort((a, b) => a.name.localeCompare(b.name));
                    break;
                case LocationType.LOCATION:
                    selectedLeafLocationList.push(selectedLocation);
                    break;
                default:
                    break;
            }
        const locationPositionList = selectedLeafLocationList?.map((loc) => ({
            locationId: loc.id,
            locationName: loc.name,
            devices: dataset.find((posData) => posData.locationId === loc.id)?.devices || undefined,
        }));
        setModifiedCameraPositions(locationPositionList);
        setLoading(false);
    };

    const getCameraPositions = async () => {
        try {
            getCameraPositionsAbortControllerRef.current = new AbortController();
            const { signal } = getCameraPositionsAbortControllerRef.current;
            const response = await executeGetDeviceSnapshotsRequest(signal);
            if (response) {
                handleLocationSelection(response);
                setCameraPositions(response);
            } else setCameraPositions([]);
            setLoading(false);
        } catch (error: unknown) {
            setCameraPositions([]);
            setLoading(false);
        }
    };

    const updateCameraPosition = async (id: string) => {
        try {
            updateCameraPositionAbortControllerRef.current = new AbortController();
            const { signal } = updateCameraPositionAbortControllerRef.current;
            const response = await executeUpdateDeviceSnapshots(id, signal);
            if (response) {
                const { locationId, deviceId, reference } = response;
                const clonedCameraPositions = [...cameraPositions];
                clonedCameraPositions.forEach((location) => {
                    if (location.locationId === locationId) {
                        location.devices?.forEach((device) => {
                            if (device.deviceId === deviceId) device.reference = reference;
                        });
                    }
                });
                setCameraPositions(() => clonedCameraPositions);
                setIsUpdating(false);
            }
        } catch (error: unknown) {
            setCameraPositions(cameraPositions);
            setIsUpdating(false);
        }
    };

    const handleCameraPositionUpdate = async (deviceId: string) => {
        setIsUpdating(true);
        await updateCameraPosition(deviceId);
    };

    return (
        <Grid container>
            <Grid width="100%" paddingBottom={2}>
                <SectionHeading
                    heading={HEADER_CAMERA_POSITION_COMPARISON}
                    icon={<IoGitCompareSharp className="icon" />}
                />
            </Grid>
            <Grid
                width="100%"
                minHeight="40vh"
                display={loading ? "flex" : undefined}
                justifyContent={loading ? "center" : undefined}
                alignItems={loading ? "end" : undefined}
            >
                <LoadingWrapper loaderStyles={{ height: "20vw !important" }} isLoading={loading}>
                    <Locations
                        isDataUpdating={isUpdating}
                        cameraPositions={modifiedCameraPositions}
                        updateCameraPosition={handleCameraPositionUpdate}
                    />
                </LoadingWrapper>
            </Grid>
        </Grid>
    );
}

export default CameraPositionComparer;
