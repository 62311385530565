import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import { FaParking } from "react-icons/fa";
import { executeGetRealTimeDetectionsRequest } from "api/common/realtime-api";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    checkLocationIdChanged,
    FetchFunctionParams,
    getUpdatedStartEndTimeBaseRange,
    isStoreTimeUpToDate,
} from "utils/common";
import { useAppDispatch } from "store";
import { Location } from "reducers/newLocation.reducer";
import { BsBoxArrowInRight, BsBoxArrowRight } from "react-icons/bs";
import { executeGetDetectionsRequest } from "api/common/detections.api";
import { BikeRackStatus } from "types/chart-configs/enums/common";
import StatsCard from "components/cards/StatsCard";
import { LocationType } from "enums/location.enums";
import { Classification } from "enums/classifications.enum";
import { setTimeRange } from "reducers/time.reducer";
import { DetectionsQuery } from "types";
import CountStatsCard from "./CountStatsCard";

interface StatisticsCardsProps {
    group?: Location;
}

export interface IDetections {
    locationId?: string;
    fromTime?: string;
    toTime?: string;
    classification?: string | string[];
    level?: number;
    field1?: string;
    includeChildLocations?: boolean;
}

export interface IDetectionsUpdate {
    locationId?: string;
    type?: string;
    classification?: string;
    value?: number;
}

const StatisticsCards: React.FC<StatisticsCardsProps> = () => {
    const { locationId } = useParams();
    const { startTime, endTime, selectedRange } = useSelector((state) => state.time);
    const [enteredBicyclesCount, setEnteredBicyclesCount] = useState<number>(0);
    const [realtimeBicyclesCount, setRealtimeBicyclesCount] = useState<number>(0);
    const [exitBicyclesCount, setExitBicyclesCount] = useState<number>(0);
    const [isEnteredBicyclesCountLoading, setIsEnteredBicyclesCountLoading] = useState<boolean>(false);
    const [isRealtimeBicyclesCountLoading, setIsRealtimeBicyclesCountLoading] = useState<boolean>(false);
    const [isExitBicyclesCountLoading, setIsExitBicyclesCountLoading] = useState<boolean>(false);
    const enteredBicyclesAbortControllerRef = useRef<AbortController | null>(null);
    const realtimeBicyclesAbortControllerRef = useRef<AbortController | null>(null);
    const exitBicyclesAbortControllerRef = useRef<AbortController | null>(null);
    const { locations } = useSelector((state) => state.newLocation);
    const dispatch = useAppDispatch();

    const fetchData = async ({ startTime, endTime }: FetchFunctionParams) => {
        await Promise.all([
            getRealtimeBicyclesDetections({
                locationId,
                classification: Classification.UNATTENDED_BICYCLE,
                includeChildLocations: !isLeafLocation(),
            }),
            getEntranceBicyclesDetections({
                locationId,
                fromTime: startTime,
                toTime: endTime,
                classification: [BikeRackStatus.ARRIVAL],
            }),
            getExitBicyclesDetections({
                locationId,
                fromTime: startTime,
                toTime: endTime,
                classification: [BikeRackStatus.DEPARTURE],
            }),
        ]);
    };

    async function getRealtimeBicyclesDetections(data?: IDetections): Promise<void> {
        try {
            realtimeBicyclesAbortControllerRef.current = new AbortController();
            const { signal } = realtimeBicyclesAbortControllerRef.current;
            setIsRealtimeBicyclesCountLoading(true);
            const response = await executeGetRealTimeDetectionsRequest(data, {
                signal,
                disableNotification: checkLocationIdChanged(locationId, locations),
            });
            if (response) {
                setRealtimeBicyclesCount(response.value);
            } else {
                setRealtimeBicyclesCount(0);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsRealtimeBicyclesCountLoading(false);
        }
    }

    async function getEntranceBicyclesDetections(data?: DetectionsQuery): Promise<void> {
        try {
            enteredBicyclesAbortControllerRef.current = new AbortController();
            const { signal } = enteredBicyclesAbortControllerRef.current;
            setIsEnteredBicyclesCountLoading(true);
            const response = await executeGetDetectionsRequest(data, {
                signal,
                disableNotification: checkLocationIdChanged(locationId, locations),
            });
            if (response && Array.isArray(response)) {
                let count = 0;
                // eslint-disable-next-line no-return-assign
                response.forEach((itm) => (count = +itm.count + count));
                setEnteredBicyclesCount(count);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsEnteredBicyclesCountLoading(false);
        }
    }

    async function getExitBicyclesDetections(data?: DetectionsQuery): Promise<void> {
        try {
            exitBicyclesAbortControllerRef.current = new AbortController();
            const { signal } = exitBicyclesAbortControllerRef.current;
            setIsExitBicyclesCountLoading(true);
            const response = await executeGetDetectionsRequest(data, {
                signal,
                disableNotification: checkLocationIdChanged(locationId, locations),
            });
            if (response && Array.isArray(response)) {
                let count = 0;
                // eslint-disable-next-line no-return-assign
                response.forEach((itm) => (count = +itm.count + count));
                setExitBicyclesCount(count);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsExitBicyclesCountLoading(false);
        }
    }

    useEffect(() => {
        fetchData({ startTime, endTime });
        return () => {
            enteredBicyclesAbortControllerRef.current?.abort();
            realtimeBicyclesAbortControllerRef.current?.abort();
            exitBicyclesAbortControllerRef.current?.abort();
        };
    }, [locationId, startTime, endTime]);

    useEffect(() => {
        if (!isStoreTimeUpToDate(selectedRange, startTime)) {
            const { fromTime, toTime, range } = getUpdatedStartEndTimeBaseRange(selectedRange);
            dispatch(
                setTimeRange({
                    startTime: fromTime,
                    endTime: toTime,
                    selectedRange: range,
                }),
            );
        }
    }, [locationId]);

    const isLeafLocation = () => {
        const loc = locations.find((location: Location) => location.id === locationId);
        if (loc) return loc.type === LocationType.LOCATION;
        return false;
    };

    return (
        <Grid gap={2} className="stats-container m-0 p-0">
            <StatsCard
                isLoading={isEnteredBicyclesCountLoading}
                title={
                    isLeafLocation() ? "Total number of bicycle entries" : "Total number of bicycles entered"
                }
                count={enteredBicyclesCount}
                icon={<BsBoxArrowInRight fontSize="85px" strokeWidth="0.5px" />}
                iconStyles="Icon-wrapper icon-background-none"
            />
            <StatsCard
                isLoading={isExitBicyclesCountLoading}
                title={isLeafLocation() ? "Total number of bicycle exits" : "Total number of bicycles left the site"}
                count={exitBicyclesCount}
                icon={<BsBoxArrowRight fontSize="80px" strokeWidth="0.5px" />}
                iconStyles="Icon-wrapper icon-background-none"
            />
            <CountStatsCard
                isLoading={isRealtimeBicyclesCountLoading}
                title={isLeafLocation() ? "Bicycles at Bike Rack" : "Number of bicycles in bike racks"}
                count={realtimeBicyclesCount}
                icon={<FaParking fontSize="80px" fontWeight={700} />}
            />
        </Grid>
    );
};

export default StatisticsCards;
