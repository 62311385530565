import ExecuteAsync from "utils/api-client";
import { ApiEndpoints } from "configs";
import { createQueryParams } from "utils/create-query-params";
import { RequestOptions } from "axios-types";
import { IDoughnutChartCardData } from "components/cards/DoughnutChartCard";
import {
    DetectionsAverageQuery,
    DetectionsBehaviorQuery,
    DetectionsByGroupQuery,
    DetectionsByLocationQuery,
    DetectionsOvertimeQuery,
    DetectionsQuery,
    IByLocation,
} from "types";
import { IDetectedCountData } from "features/app-taxi-ranking/chart/DetectedCountChart";
import { IAverageWaitingTimeData } from "features/app-taxi-ranking/chart/AverageWaitingTimeChart";
import { IWaitingTimeChartParams } from "pages/app-taxi-ranking/location/ChartLayout";

interface DetectionByLocation {
    locationId: string;
    locationgroupids: string[];
    classification: string;
    count: string; // Needs to be parsed
}

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
export function executeGetDetectionsRequest(data?: DetectionsQuery, options?: RequestOptions) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/reporting/detections/${createQueryParams(data)}`,
        signal: options?.signal,
        disableNotification: options?.disableNotification,
    });
}

export function executeGetDetectionsByLocationsRequest(data?: DetectionsByLocationQuery, options?: RequestOptions) {
    return ExecuteAsync<any, DetectionByLocation[]>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/reporting/detections/by_locations${createQueryParams(data)}`,
        signal: options?.signal,
        disableNotification: options?.disableNotification,
    });
}

export function executeGetDetectionsOvertimeRequest(
    locationId: string,
    data?: DetectionsOvertimeQuery,
    options?: RequestOptions,
) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/reporting/detections/overtime/${locationId}${createQueryParams(data)}`,
        signal: options?.signal,
        disableNotification: options?.disableNotification,
    });
}

export function executeGetDetectionsBehaviourRequest(
    locationId: string,
    data?: DetectionsBehaviorQuery,
    options?: RequestOptions,
) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/reporting/detections/behaviors/${locationId}${createQueryParams(data)}`,
        signal: options?.signal,
        disableNotification: options?.disableNotification,
    });
}

export function executeGetDetectionsTaxiRequest(data?: DetectionsQuery, options?: RequestOptions) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/reporting/detections/${createQueryParams(data)}`,
        signal: options?.signal,
        disableNotification: options?.disableNotification,
    });
}

export async function executeGetDetectionsByLocationsTaxiRequest(
    data?: DetectionsByLocationQuery,
    options?: RequestOptions,
): Promise<IByLocation[]> {
    const response = await ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/reporting/detections/by_locations${createQueryParams(data)}`,
        signal: options?.signal,
    });
    return response.map((item: any) => {
        return {
            count: item.count,
            locationGroupIds: item.locationGroupIds,
            locationId: item.locationId,
            class: item.classification,
            groupId: item.groupId,
        };
    });
}

export async function executeGetOvertimeDetectionsTaxiRequest(
    locationId: string,
    data?: DetectionsOvertimeQuery,
    options?: RequestOptions,
): Promise<IDetectedCountData[]> {
    const response = await ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/reporting/detections/overtime/${locationId}${createQueryParams(data)}`,
        signal: options?.signal,
        disableNotification: options?.disableNotification,
    });
    return response.map((item: any) => {
        return {
            inTime: item.inTime,
            count: item.count,
        };
    });
}

export async function executeGetOvertimeIncidentDetectionsTaxiRequest(
    data?: IWaitingTimeChartParams,
    options?: RequestOptions,
): Promise<IDetectedCountData[]> {
    const response = await ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/incidents/incident/overtime/${createQueryParams(data)}`,
        signal: options?.signal,
    });
    return response.map((item: any) => {
        return {
            inTime: item.intime,
            count: item.count,
        };
    });
}

export async function executeGetAverageWaitingTimeTaxiRequest(
    locationId: string,
    data?: DetectionsAverageQuery,
    options?: RequestOptions,
): Promise<IAverageWaitingTimeData[]> {
    const response = await ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/reporting/detections/average/${locationId}${createQueryParams(data)}`,
        signal: options?.signal,
    });
    return response.map((item: any) => {
        return {
            inTime: item.inTime,
            max: item.max,
        };
    });
}

export async function executeGetDetectionsByLgaTaxiRequest(
    data?: DetectionsByGroupQuery,
    options?: RequestOptions,
): Promise<IDoughnutChartCardData[]> {
    const response = await ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/reporting/detections/by_group/${createQueryParams(data)}`,
        signal: options?.signal,
    });
    return response.map((item: any) => {
        return {
            count: item.count,
            locationId: item?.locationId,
            label: item?.label,
        };
    });
}

export function executeGetSnapshotRequest(data: any, options?: RequestOptions) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/incidents/incident/${data.incidentId}/snapshot`,
        signal: options?.signal,
        disableNotification: options?.disableNotification,
    });
}
