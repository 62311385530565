import { Navigate, Outlet } from "react-router-dom";
import { NavigateNotFound } from "constants/paths.constants";
import home from "./home";
import incidents from "./incidents";
import logs from "./logs";
import reports from "./reports";
import plateSearchReports from "./plate-search-reports";
import videoRequests from "./video-requests";
import notFound from "./not-found";
import forbidden from "./forbidden";
import cameraPosition from "./camera-position";

export const appPrefix = "taxi-ranking";
export const directUrl = "/taxiranks";
export const appDefaultRoute = `/${appPrefix}/home`;

const root = [
    {
        path: appPrefix,
        element: <Outlet />,
        children: [
            { path: "", element: <Navigate to="home" replace /> },
            { path: "*", element: <Navigate to={NavigateNotFound} replace /> },
            { ...home },
            { ...incidents },
            { ...logs },
            { ...plateSearchReports },
            { ...videoRequests },
            { ...reports },
            { ...cameraPosition },
            { ...notFound },
            { ...forbidden },
        ],
    },
    {
        path: directUrl,
        element: <Navigate to={appDefaultRoute} replace />,
    },
];
export default root;
