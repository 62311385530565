import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactElement, ReactNode } from "react";
import { SxProps } from "@mui/material";

export interface AccordionWrapperProps {
    summary: ReactNode;
    details: ReactNode;
    defaultExpanded?: boolean;
    disabled?: boolean;
    customStyles?: SxProps;
}

const AccordionWrapper = ({
    details,
    summary,
    customStyles,
    defaultExpanded = true,
    disabled = false,
}: AccordionWrapperProps): ReactElement => (
    <Accordion defaultExpanded={defaultExpanded} disabled={disabled} sx={customStyles}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>{summary}</AccordionSummary>
        <AccordionDetails>{details}</AccordionDetails>
    </Accordion>
);

export default AccordionWrapper;
