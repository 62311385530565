/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, Typography, useMediaQuery } from "@mui/material";
import HomeCard from "features/app-air-quality/cards/HomeCard";
import HistoricalAnalysis, {
    LocationHistoricalData,
} from "features/app-air-quality/historical-analysis/HistoricalAnalysis";
import { ChartData } from "features/app-air-quality/historical-analysis/chart/HistoricalAnalysisChart";
import { Helmet } from "react-helmet";
import { AppAqTitlePrefix } from "constants/app.constants";
import PlatformityLogo from "assets/images/platformity-favicon-logo.png";
import { HistoricalAnalysisField } from "features/app-air-quality/historical-analysis/filter/HistoricalAnalysisFilter";
import { pickerRanges } from "components/common/CommonDateRangePicker";
import { useLayoutEffect, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { findLocation } from "utils/search-tree";
import { Location } from "reducers/newLocation.reducer";
import { executeGetHistoricalDataRequest } from "api/app-air-quality/aq-home-layout.api";
import { getLocationsFromTree } from "utils/get-locations-from-tree";
import { getRelativeTime } from "utils/common";
import { DEFAULT_HISTORICAL_ANALYSIS_DATA_TIME_PERIOD } from "constants/time.constants";
import { DetectionsHistoricalQuery } from "types";

export const getDefaultFilterValues = () => {
    return {
        type: "temp",
        time: {
            fromTime: getRelativeTime(DEFAULT_HISTORICAL_ANALYSIS_DATA_TIME_PERIOD).toISOString(),
            toTime: new Date().toISOString(),
            range: pickerRanges.Last24Hours,
        },
    };
};

const Dashboard = () => {
    const [width, setWidth] = useState<string>("");
    const { isDrawerOpen } = useSelector((state) => state.system);
    const [chartData, setChartData] = useState<ChartData[]>([]);
    const [windowGap, setWindowGap] = useState<number>();
    const { locationsTree } = useSelector((state) => state.newLocation);
    const { regionId, schoolId } = useParams();
    const [rooms, setRooms] = useState<Location[]>([]);
    const [location, setLocation] = useState<Location | undefined>(undefined);
    const [filterValue, setFilterValue] = useState<HistoricalAnalysisField>(getDefaultFilterValues());
    const getHistoricalData = async (data: DetectionsHistoricalQuery) => {
        try {
            const res: any = await executeGetHistoricalDataRequest(data);
            mapToGraph(res);
            // eslint-disable-next-line no-empty
        } catch (error) {}
    };
    const mapToGraph = async (dataList: LocationHistoricalData[]) => {
        const om: any = await Promise.all(
            dataList.map(async (location) => {
                const im = await Promise.all(
                    location.data.map((data) => {
                        return {
                            x: data.time,
                            y: data.avg,
                        };
                    }),
                );
                return {
                    label: location.location.name,
                    data: im,
                    borderColor: `#${Math.floor(Math.random() * 16777215)
                        .toString(16)
                        .padStart(6, "0")
                        .toUpperCase()}`,
                };
            }),
        );
        setChartData(om);
    };

    const scaleTimeWindow = (): number => {
        const SECONDS = 1000;
        const MINUTES = SECONDS * 60;
        const HOURS = MINUTES * 60;
        const difference = Math.abs(
            (new Date(filterValue.time.toTime).getTime() - new Date(filterValue.time.fromTime).getTime()) / HOURS,
        );
        if (difference >= 24) {
            return 60;
        }
        if (difference >= 12) {
            return 30;
        }
        if (difference >= 4) {
            return 20;
        }
        if (difference >= 2) {
            return 15;
        }

        return 5;
    };

    useEffect(() => {
        const windowSize = scaleTimeWindow();
        setWindowGap(windowSize);
        getHistoricalData({
            classification: filterValue.type,
            fromTime: filterValue.time.fromTime,
            toTime: filterValue.time.toTime,
            timeWindowSize: windowSize,
            isAVG: true,
        });
    }, [filterValue]);

    const fhd = useMediaQuery("(min-width:1920px)");
    const wsxga = useMediaQuery("(min-width:1680px)");
    const wxga = useMediaQuery("(min-width:1440px)");
    const hd = useMediaQuery("(min-width:1366px)");
    const xga = useMediaQuery("(min-width:1024px)");

    useLayoutEffect(() => {
        if (fhd) {
            setWidth(isDrawerOpen ? "13vw" : "15vw");
        } else if (wsxga) {
            setWidth("15vw");
        } else if (wxga) {
            setWidth("18vw");
        } else if (hd) {
            setWidth("22vw");
        } else if (xga) {
            setWidth("28vw");
        }
    }, [fhd, wsxga, wxga, hd, xga, isDrawerOpen]);

    useEffect(() => {
        const matchingLocation = findLocation(locationsTree, regionId ?? schoolId ?? "");
        if (location?.id !== matchingLocation?.id) setLocation(matchingLocation);

        const tree = matchingLocation ? [matchingLocation] : locationsTree;
        const rooms = getLocationsFromTree(tree);
        setRooms(rooms);
    }, [locationsTree, schoolId, regionId, location]);

    return (
        <div>
            <Helmet>
                <title>{AppAqTitlePrefix} | Dashboard</title>
                <link rel="icon" id="aq-dashboard" type="image/png" href={PlatformityLogo} />
            </Helmet>
            <Typography fontSize={40} color="#001B4F" fontWeight={600} height="60px">
                {location?.name ? location.name : "Dashboard"}
            </Typography>
            {width && (
                <Grid container spacing={2} columnSpacing="12px" paddingTop={2}>
                    {rooms.map((i) => {
                        return (
                            <Grid item xs="auto" key={`${i.id}-${location?.id}`}>
                                <HomeCard cardHeading={i.name} width={width} locationId={i.id} />
                            </Grid>
                        );
                    })}
                </Grid>
            )}
            <Grid item xs={12} paddingTop={3}>
                <HistoricalAnalysis
                    defaultFilterValues={getDefaultFilterValues()}
                    chartData={chartData}
                    initialFilterValues={filterValue}
                    onApply={(value) => {
                        setFilterValue(value);
                    }}
                    onClear={(value) => {
                        setFilterValue(value);
                    }}
                    windowGap={windowGap}
                />
            </Grid>
        </div>
    );
};

export default Dashboard;
