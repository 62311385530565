/* eslint-disable no-empty */
import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import { FaCarAlt, FaTaxi } from "react-icons/fa";
import { AiFillWarning } from "react-icons/ai";
import { MdAirlineSeatReclineNormal } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { executeGetDetectionsTaxiRequest } from "api/common/detections.api";
import {
    CLASS_INCIDENTS,
    CLASS_TAXIES,
    CLASS_EMBARK_PASSENGERS,
    CLASS_WATTAXIES,
    CLASS_NONTAXIES,
} from "constants/class.constants";
import { Location } from "reducers/newLocation.reducer";
import { executeGetIncidentDetectionsTaxiRequest } from "api/app-taxi-ranking/taxi-incidents.api";
import DoughnutChartCard, { IDoughnutChartCardData } from "components/cards/DoughnutChartCard";
import { FetchFunctionParams, makeApiCallWithUpdateTime } from "utils/common";
import { useAppDispatch } from "store";
import ProtectedView from "components/common/ProtectedView";
import { SCOPES } from "enums/scopes.enum";
import useHasScope from "hooks/useHasScope";
import { DetectionsQuery } from "types";

interface StatisticsZoneLocationCardsProps {
    group?: Location | null;
}
export interface DetectionParams {
    locationId?: string;
    fromTime: string;
    toTime: string;
    classification?: string;
    level: number;
    type?: string;
}

const StatisticsZoneLocationCards: React.FC<StatisticsZoneLocationCardsProps> = ({ group }) => {
    const hasReadIncidentsScope = useHasScope(SCOPES.READ_INCIDENTS);
    const { locationId } = useParams();
    const { startTime, endTime, selectedRange } = useSelector((state) => state.time);
    const [taxiData, setTaxiData] = useState<IDoughnutChartCardData[]>([]);
    const [nonTaxiData, setNonTaxiData] = useState<IDoughnutChartCardData[]>([]);
    const [passengerData, setPassengerData] = useState<IDoughnutChartCardData[]>([]);
    const [incidentData, setIncidentData] = useState<IDoughnutChartCardData[]>([]);
    const [isChart, setIsChart] = useState<boolean>(true);
    const [isLoadingTaxi, setIsLoadingTaxi] = useState(false);
    const [isLoadingNonTaxi, setIsLoadingNonTaxi] = useState(false);
    const [isLoadingPassengerData, setIsLoadingPassengerData] = useState(false);
    const [isLoadingIncidentData, setIsLoadingIncidentData] = useState(false);
    const [incidentCount, setIncidentCount] = useState<string>("0");
    const taxiAbortControllerRef = useRef<AbortController | null>(null);
    const nonTaxiAbortControllerRef = useRef<AbortController | null>(null);
    const passengerControllerRef = useRef<AbortController | null>(null);
    const incidentControllerRef = useRef<AbortController | null>(null);
    const dispatch = useAppDispatch();

    const fetchData = async ({ startTime, endTime }: FetchFunctionParams) => {
        let level = 0;
        if (locationId !== undefined) {
            level = 3;
        } else if (group?.type === "region") {
            level = 1;
        } else if (group?.type === "zone") {
            level = 2;
        }
        await Promise.all([
            getTaxiesDetections(),
            getNonTaxiesDetections({
                locationId,
                fromTime: startTime,
                toTime: endTime,
                classification: CLASS_NONTAXIES,
            }),
            getPassengersDetections({
                locationId,
                fromTime: startTime,
                toTime: endTime,
                classification: CLASS_EMBARK_PASSENGERS,
            }),
            hasReadIncidentsScope && getIncidentsDetections({
                locationId,
                fromTime: startTime,
                toTime: endTime,
                type: CLASS_INCIDENTS,
                level,
            }),
        ]);
    };

    async function getTaxiesDetections() {
        setIsChart(false);
        try {
            taxiAbortControllerRef.current = new AbortController();
            const { signal } = taxiAbortControllerRef.current;
            const watTaxiArray: { count: string; label: string }[] = [];
            setIsLoadingTaxi(true);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const responseNormalTaxi: any = await executeGetDetectionsTaxiRequest(
                {
                    locationId,
                    fromTime: startTime,
                    toTime: endTime,
                    classification: CLASS_TAXIES,
                },
                { signal },
            );

            const responseWatTaxi = await executeGetDetectionsTaxiRequest({
                locationId,
                fromTime: startTime,
                toTime: endTime,
                classification: CLASS_WATTAXIES,
            });
            if (responseWatTaxi.length !== 0) {
                const objWatTaxi = {
                    label: "Wat Taxis",
                    count: responseWatTaxi[0]?.count || 0,
                };
                watTaxiArray.push(objWatTaxi);
            }
            if (responseNormalTaxi.length !== 0) {
                const watTaxiCount = responseWatTaxi[0]?.count ? responseWatTaxi[0]?.count : 0;
                const objTaxi = {
                    label: "Normal Taxis",
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    count: (responseNormalTaxi[0]?.count - watTaxiCount).toString() || "0",
                };
                watTaxiArray.push(objTaxi);
            }
            setTaxiData(watTaxiArray);
            setIsChart(true);
        } catch (error) {
        } finally {
            // setLoading(false);
            setIsLoadingTaxi(false);
        }
    }

    async function getNonTaxiesDetections(data?: DetectionsQuery) {
        try {
            nonTaxiAbortControllerRef.current = new AbortController();
            const { signal } = nonTaxiAbortControllerRef.current;
            setIsLoadingNonTaxi(true);
            const response = await executeGetDetectionsTaxiRequest(data, {
                signal,
            });
            if (response && Array.isArray(response)) {
                setNonTaxiData(response);
            }
            setIsLoadingNonTaxi(false);
        } catch (e) {
        } finally {
            // setLoading(false);
        }
    }

    async function getPassengersDetections(data?: DetectionsQuery) {
        try {
            passengerControllerRef.current = new AbortController();
            const { signal } = passengerControllerRef.current;
            setIsLoadingPassengerData(true);
            const response = await executeGetDetectionsTaxiRequest(data, {
                signal,
            });
            if (response && Array.isArray(response)) {
                setPassengerData(response);
            }
            setIsLoadingPassengerData(false);
        } catch (e) {
        } finally {
            // setLoading(false);
        }
    }

    async function getIncidentsDetections(data?: DetectionParams) {
        try {
            incidentControllerRef.current = new AbortController();
            const { signal } = incidentControllerRef.current;
            setIsLoadingIncidentData(true);
            const response = await executeGetIncidentDetectionsTaxiRequest(data, { signal });
            if (response && Array.isArray(response)) {
                setIncidentData(response);
                let total = 0;
                response.forEach((element) => {
                    total += +element.count;
                });
                setIncidentCount(total.toString());
            }
            setIsLoadingIncidentData(false);
        } catch (e) {
        } finally {
            // setLoading(false);
        }
    }

    useEffect(() => {
        makeApiCallWithUpdateTime(selectedRange, { startTime, endTime }, fetchData, dispatch);
        return () => {
            setTaxiData([]);
            setNonTaxiData([]);
            setPassengerData([]);
            setIncidentData([]);
            setIncidentCount("0");
            taxiAbortControllerRef.current?.abort();
            nonTaxiAbortControllerRef.current?.abort();
            passengerControllerRef.current?.abort();
            incidentControllerRef.current?.abort();
        };
    }, [startTime, endTime, locationId, hasReadIncidentsScope]);

    return (
        <Grid container className="stats-container">
            <DoughnutChartCard
                isLoading={isLoadingTaxi}
                title="Total number of normal taxis and WAT taxis"
                count="0"
                icon={<FaTaxi />}
                isChart={isChart}
                className="donut-Icon-wrapper"
                data={taxiData}
                labelName="label"
            />

            <DoughnutChartCard
                isLoading={isLoadingNonTaxi}
                title="Total number of non-taxis"
                count={Array.isArray(nonTaxiData) && nonTaxiData.length > 0 ? nonTaxiData[0]?.count : "0"}
                icon={<FaCarAlt />}
                isChart={false}
                className="donut-Icon-wrapper"
                data={nonTaxiData}
            />

            <DoughnutChartCard
                isLoading={isLoadingPassengerData}
                title="Total number of passengers"
                count={Array.isArray(passengerData) && passengerData.length > 0 ? passengerData[0]?.count : "0"}
                icon={<MdAirlineSeatReclineNormal />}
                isChart={false}
                className="donut-Icon-wrapper"
                data={passengerData}
            />

            <ProtectedView filterScopes={[SCOPES.READ_INCIDENTS]} shouldHide>
                <DoughnutChartCard
                    isLoading={isLoadingIncidentData}
                    title="Total number of incidents"
                    count={incidentCount}
                    icon={<AiFillWarning />}
                    isChart={false}
                    className="donut-Icon-wrapper donut-red-Icon-wrapper"
                    data={incidentData}
                    labelName="incident"
                />
            </ProtectedView>
        </Grid>
    );
};

export default StatisticsZoneLocationCards;
