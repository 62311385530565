import CameraPositionLayout from "layouts/app-taxi-ranking/camera-position";
import { ProtectedLayout } from "layouts/common/general";
import CameraPosition from "pages/common/camera-position";
import WithScopes from "components/common/WithScopes";
import { SCOPES } from "enums/scopes.enum";

const root = {
    path: "admin-camera-comparison",
    element: (
        <ProtectedLayout>
            <CameraPositionLayout />
        </ProtectedLayout>
    ),
    children: [
        {
            path: "",
            element: (
                <WithScopes filterScopes={[SCOPES.VIEW_CAMERA_POSITION]}>
                    <CameraPosition />
                </WithScopes>
            ),
        },
        {
            path: ":locationId",
            element: (
                <WithScopes filterScopes={[SCOPES.VIEW_CAMERA_POSITION]}>
                    <CameraPosition />
                </WithScopes>
            ),
        },
        {
            path: "location/:locationId",
            element: (
                <WithScopes filterScopes={[SCOPES.VIEW_CAMERA_POSITION]}>
                    <CameraPosition />
                </WithScopes>
            ),
        },
    ],
};
export default root;
