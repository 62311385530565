import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import { forwardRef } from "react";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
/* eslint @typescript-eslint/no-unused-vars: ["off"] */
interface IIotVisionButton {
    additionalProps?: any;
}
const CustomizedButton = styled(Button)(({ theme }) => ({
    backgroundColor: `${theme.palette.primary.main}`,
    textTransform: "none",
    fontSize: "14px",
    lineHeight: "21px",
    borderRadius: "20px",
    borderColor: `${theme.palette.secondary.main}`,
    height: "33px",
    color: "white",
    padding: "0px 43px",
    border: "1px solid",
    "&:hover": {
        background: `${theme.palette.primary.main}`,
        cursor: "pointer",
    },
}));
const IotVisionButton = forwardRef<HTMLButtonElement, IIotVisionButton & JSX.IntrinsicAttributes & ButtonProps>(({
    additionalProps,
    ...rest
}, ref) => {
    return <CustomizedButton ref={ref} {...rest} />;
});

export default IotVisionButton;
