import { Box, styled } from "@mui/material";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import IotVisionButton from "components/common/IotVisionButton";
import { ReactElement, useState } from "react";
import ProtectedView from "components/common/ProtectedView";
import { SCOPES } from "enums/scopes.enum";

const customIotVisionButtonStyles = {
    height: "33px",
    lineHeight: 1,
    backgroundColor: "white",
    padding: { xs: "14px 18px", sm: "18px 15px", md: "18px 20px" },
    fontSize: { xs: "11px", lg: "14px" },
    marginX: { xs: "0%", sm: "5%", lg: "10%" },
};

const StyledTrendingFlatIcon = styled(TrendingFlatIcon)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        transform: "rotate(-90deg)",
        fontSize: 40,
    },
    [theme.breakpoints.up("sm")]: {
        transform: "rotate(-90deg)",
        fontSize: 50,
    },
    [theme.breakpoints.up("md")]: {
        transform: "rotate(180deg)",
        fontSize: 60,
    },
}));

export interface UpdatingButtonProps {
    currentUrl?: string;
    buttonText: string;
    isButtonDisabled: boolean;
    handleButtonClick: () => Promise<void>;
}

const UpdatingButton = ({
    currentUrl,
    buttonText,
    isButtonDisabled,
    handleButtonClick,
}: UpdatingButtonProps): ReactElement => {
    const [isButtonVisible, setIsButtonVisible] = useState(false);

    const buttonRefCallback = (node: HTMLButtonElement | null) => {
        if (node) setIsButtonVisible(true);
        else setIsButtonVisible(false);
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            marginTop={{xs: 0, md: isButtonVisible ? 0 : "-1rem"}}
            flexDirection={{ xs: "column-reverse", md: "column" }}
        >
            <ProtectedView shouldHide filterScopes={[SCOPES.UPDATE_DEVICE_SNAPSHOT]}>
                <IotVisionButton
                    ref={buttonRefCallback}
                    sx={{
                        ...customIotVisionButtonStyles,
                        borderColor: isButtonDisabled ? "gray" : "#6b439d",
                        color: isButtonDisabled ? "gray !important" : "#6b439d !important",
                        "&:hover": {
                            background: "white",
                        },
                    }}
                    disabled={isButtonDisabled}
                    onClick={handleButtonClick}
                >
                    {buttonText}
                </IotVisionButton>
            </ProtectedView>
            <StyledTrendingFlatIcon color={!currentUrl ? "secondary" : "primary"} />
        </Box>
    );
};

export default UpdatingButton;
