/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useState } from "react";
import SectionHeading from "components/headings/SectionHeading";
import PaperWrapper from "components/wrappers/PaperWrapper";
import { GridColDef, GridRenderCellParams, GridRowsProp, GridSortModel } from "@mui/x-data-grid";
import { BiCalendarStar } from "react-icons/bi";
import LogsFilterPanel from "pages/common/logs/LogsFilterPanel";
import { getLogsListRequest } from "api/common/logs.api";
import IotVisionLink from "components/common/IotVisionLink";
import { Helmet } from "react-helmet";
import { AppTitlePrefix } from "constants/app.constants";
import DateView from "components/dates/DateView";
import IotVisionTable from "components/common/IotVisionTable";
import { ASC_ORDER, DESC_ORDER } from "constants/common.constants";
import IotVisionToolTips from "components/common/IotVisionToolTips";
import { useSearchParams, useNavigate, createSearchParams } from "react-router-dom";
import { Log } from "types/logs/log";
import { enAU } from "date-fns/locale";
import NswLogo from "../../../assets/images/nsw-logo.png";

export default function LogPage() {
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [ids, setIds] = useState<string[]>([]);
    const [logsListData, setLogsListData] = React.useState<GridRowsProp>([]);
    const [previousPageIndex, setPreviousPageIndex] = useState<number>(0);
    const [nextStartKey, setNextStartKey] = useState<string | null>(null);
    const [pageKeyArray, setPageKeyArray] = useState<string[]>([]);
    const [sortDateTimeKey, setSortDateTimeKey] = useState<string | null>(null);
    const [sortDateTimeKeyArray, setSortDateTimeKeyArray] = useState<string[]>([]);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{ field: "dateTime", sort: DESC_ORDER }]);
    const logsFilterAbortControllerRef = useRef<AbortController | null>(null);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const pageSize = 10;
    const logTableColumns: GridColDef[] = [
        {
            field: "dateTime",
            headerName: "Date and Time",
            renderCell: (params: GridRenderCellParams) => (
                <DateView dateFormat="Pp" options={{ locale: enAU }}>
                    {params.row.dateTime}
                </DateView>
            ),
            flex: 1,
            disableColumnMenu: true,
            sortable: true,
        },
        {
            field: "section",
            headerName: "Section",
            flex: 0.5,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "externalId",
            headerName: "Id",
            flex: 0.5,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "username",
            headerName: "Username",
            flex: 1.25,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => (
                <IotVisionLink
                    linkProps={{
                        component: "button",
                        onClick: () => {
                            const filters = {
                                ...Object.fromEntries([...searchParams]),
                                userId: params.row.user.userId,
                            };
                            navigate({
                                pathname: window.location.pathname,
                                search: `?${createSearchParams(filters)}`,
                            });
                        },
                    }}
                    linkText={params.value}
                />
            ),
            align: "left",
        },
        {
            field: "description",
            headerName: "Description",
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <IotVisionToolTips title={params?.row?.description || ""} arrow>
                    <span>{params?.row?.description}</span>
                </IotVisionToolTips>
            ),
            sortable: false,
            disableColumnMenu: true,
        },
    ];
    const clearPagination = () => {
        setNextStartKey(null);
        setPageKeyArray([]);
        setSortDateTimeKey(null);
        setSortDateTimeKeyArray([]);
    };

    const removeArrayDuplicate = (arr: string[]): string[] => {
        return arr.filter((key: string, index: number) => {
            return arr.indexOf(key) === index;
        });
    };

    async function executeLogDataEndPoint(pageKey?: string | null, sortDateTimeKey?: string | null): Promise<void> {
        try {
            logsFilterAbortControllerRef.current = new AbortController();
            const { signal } = logsFilterAbortControllerRef.current;
            setLoading(true);
            let response = null;
            response = await getLogsListRequest(
                {
                    ...Object.fromEntries([...searchParams]),
                    pageSize,
                    startKey: pageKey,
                    startDateTime: sortDateTimeKey,
                    scanOrder: sortModel.length > 0 ? sortModel[0]?.sort?.toUpperCase() : null,
                },
                { signal },
            );
            if (
                (Object.keys(response).length !== 0 && response.logs) !== (null || undefined) &&
                Array.isArray(response.logs)
            ) {
                const { logs, startKey, startDateTime } = response;
                const processedLogGridData = logs?.map((log: Log) => {
                    setIds((prev) => [...new Set([...prev, log.id])]);
                    return {
                        ...log,
                        username: log.user.username,
                    };
                });
                setLogsListData(processedLogGridData);
                setNextStartKey(startKey);
                setSortDateTimeKey(startDateTime);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    const handleSortModelChange = (newModel: GridSortModel) => {
        setSortModel(newModel);
        clearPagination();
        setPage(1);
    };

    const getLogsWithPaginationDetails = (pageIndex: number) => {
        if (pageIndex === 1) {
            executeLogDataEndPoint();
            nextStartKey && setPageKeyArray(removeArrayDuplicate([...pageKeyArray, nextStartKey]));
            sortDateTimeKey &&
                setSortDateTimeKeyArray(removeArrayDuplicate([...sortDateTimeKeyArray, sortDateTimeKey]));
        } else {
            setPreviousPageIndex(pageIndex - 1);
            if (previousPageIndex === pageIndex) {
                executeLogDataEndPoint(pageKeyArray[pageIndex - 2], sortDateTimeKeyArray[pageIndex - 2]);
            } else {
                executeLogDataEndPoint(nextStartKey, sortDateTimeKey);
                nextStartKey && setPageKeyArray(removeArrayDuplicate([...pageKeyArray, nextStartKey]));
                sortDateTimeKey &&
                    setSortDateTimeKeyArray(removeArrayDuplicate([...sortDateTimeKeyArray, sortDateTimeKey]));
            }
        }
    };

    useEffect(() => {
        getLogsWithPaginationDetails(1);
        setPage(0);
        return () => {
            setLogsListData([]);
            setIds([]);
            setNextStartKey(null);
            setSortDateTimeKey(null);
            logsFilterAbortControllerRef.current?.abort();
        };
    }, [sortModel, searchParams]);

    return (
        <>
            <Helmet>
                <title>{AppTitlePrefix} | Event Logs</title>
                <link rel="icon" id="tr-events" type="image/png" href={NswLogo} sizes="32x32" />
            </Helmet>
            <div className="sec-header">
                <SectionHeading heading="Event Logs" icon={<BiCalendarStar className="icon" />} />
            </div>
            <PaperWrapper>
                <LogsFilterPanel
                    onClear={() => {
                        clearPagination();
                    }}
                />
                <IotVisionTable
                    sortingOrder={[DESC_ORDER, ASC_ORDER]}
                    loading={loading}
                    columns={logTableColumns}
                    rows={logsListData}
                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    pageSize={pageSize}
                    page={page}
                    rowCount={nextStartKey ? Number.MAX_VALUE : ids.length}
                    onPageChange={(newPage) => {
                        getLogsWithPaginationDetails(newPage + 1);
                        setPage(newPage);
                    }}
                    rowsPerPageOptions={[]}
                    hideFooterPagination={false}
                    localeText={{
                        MuiTablePagination: {
                            labelDisplayedRows: ({ from, to, count }) =>
                                `${from}-${to} of ${count === Number.MAX_VALUE ? `more than ${to}` : count}`,
                        },
                    }}
                />
            </PaperWrapper>
        </>
    );
}
