import { Grid } from "@mui/material";
import { ChartData } from "features/app-dept-air-quality/historical-analysis/chart/HistoricalAnalysisChart";
// eslint-disable-next-line max-len
import { HistoricalAnalysisField } from "features/app-dept-air-quality/historical-analysis/filter/HistoricalAnalysisFilter";
import HistoricalAnalysis, {
    LocationHistoricalData,
} from "features/app-dept-air-quality/historical-analysis/HistoricalAnalysis";
import RoomAlerts from "features/app-dept-air-quality/alerts/RoomAlerts";
import RoomStatisticsCard from "features/app-dept-air-quality/statistics/RoomStatisticsCards";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AppDeptAqTitlePrefix } from "constants/app.constants";
import { Helmet } from "react-helmet";
import PlatformityLogo from "assets/images/platformity-favicon-logo.png";
import { executeGetHistoricalDataRequest } from "api/app-dept-air-quality/aq-home-layout.api";
import { AQSensorData } from "utils/threshold-data-finder/types";
import { navigateTo404Page } from "utils/navigation";
import { DetectionsHistoricalQuery } from "types";
import { Location } from "../../../reducers/newLocation.reducer";
import RoomHeader from "./RoomHeader";
import { getDefaultFilterValues } from "../dashboard/Dashboard";

function Room() {
    const { roomId } = useParams();
    const startDate = new Date();
    startDate.setHours(startDate.getHours() - 24);
    const [location, setLocation] = useState<Location | undefined>();
    const navigate = useNavigate();
    const { locations } = useSelector((state) => state.newLocation);
    const { appCode } = useSelector((state) => state.auth);
    const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
    const [roomAQStatus, setRoomAQStatus] = useState<AQSensorData | null>(null);
    const [chartData, setChartData] = useState<ChartData[]>([]);
    const [windowGap, setWindowGap] = useState<number>();
    const [filterValue, setFilterValue] = useState<HistoricalAnalysisField>(getDefaultFilterValues());

    const getHistoricalData = async (data: DetectionsHistoricalQuery) => {
        try {
            const res: LocationHistoricalData[] = (await executeGetHistoricalDataRequest(
                data,
            )) as LocationHistoricalData[];
            mapToGraph(res);
        } catch (e) {}
    };
    const mapToGraph = async (dataList: LocationHistoricalData[]) => {
        const om = await Promise.all(
            dataList.map(async (location) => {
                const im = await Promise.all(
                    location.data.map((data) => {
                        return {
                            x: data.time,
                            y: data.avg,
                        };
                    }),
                );
                return {
                    label: location.location.name,
                    data: im,
                    borderColor: `#${Math.floor(Math.random() * 16777215)
                        .toString(16)
                        .padStart(6, "0")
                        .toUpperCase()}`,
                };
            }),
        );
        setChartData(om);
    };

    const scaleTimeWindow = (): number => {
        const SECONDS = 1000;
        const MINUTES = SECONDS * 60;
        const HOURS = MINUTES * 60;
        const difference = Math.abs(
            (new Date(filterValue.time.toTime).getTime() - new Date(filterValue.time.fromTime).getTime()) / HOURS,
        );
        if (difference >= 24) {
            return 60;
        }
        if (difference >= 12) {
            return 30;
        }
        if (difference >= 4) {
            return 20;
        }
        if (difference >= 2) {
            return 15;
        }
        return 5;
    };

    useEffect(() => {
        const location: Location | undefined = locations.find((loc) => loc.id === roomId);
        const windowSize = scaleTimeWindow();
        setWindowGap(windowSize);
        if (location) {
            getHistoricalData({
                locationId: roomId,
                classification: filterValue.type,
                fromTime: filterValue.time.fromTime,
                toTime: filterValue.time.toTime,
                timeWindowSize: windowSize,
                isAVG: true,
            });
        }
    }, [filterValue]);

    useEffect(() => {
        setFilterValue(getDefaultFilterValues());
    }, [roomId]);

    useEffect(() => {
        const location: Location | undefined = locations.find((loc) => loc.id === roomId);
        if (!location) {
            navigateTo404Page(navigate, appCode, false);
        }
        setLocation(location);
    }, [roomId]);

    const lastUpdatedDate = lastUpdated ? new Date(lastUpdated).toLocaleString() : "";

    return (
        <div>
            <Helmet>
                <title>{AppDeptAqTitlePrefix} | Room</title>
                <link rel="icon" id="aq-room" type="image/png" href={PlatformityLogo} />
            </Helmet>
            <RoomHeader
                roomName={location?.name}
                serialNo={location?.metadata?.serialNo}
                roomStatus={roomAQStatus}
                lastUpdated={lastUpdatedDate}
            />
            <RoomStatisticsCard
                selectedRoom={roomId}
                setLastUpdated={setLastUpdated}
                setRoomAQStatus={setRoomAQStatus}
            />
            <Grid item xs={12} md="auto" marginTop={2}>
                <HistoricalAnalysis
                    defaultFilterValues={getDefaultFilterValues()}
                    chartData={chartData}
                    isChartLegendDisplay={false}
                    initialFilterValues={filterValue}
                    onApply={(value) => {
                        setFilterValue(value);
                    }}
                    onClear={(value) => {
                        setFilterValue(value);
                    }}
                    windowGap={windowGap}
                />
            </Grid>
            <Grid item xs={12} md="auto" marginTop={2}>
                <RoomAlerts locationId={roomId} />
            </Grid>
        </div>
    );
}

export default Room;
