import { useEffect, useState } from "react";
import { CircularProgress, Fade, styled, Typography } from "@mui/material";
import { Box, SxProps } from "@mui/system";

const customImageWrapperStyles = {
    boxShadow: "0.001em 0.001em 0.5em rgb(158, 158, 158)",
    minHeight: { xs: "32vw", md: "13vw" },
};

const StyledImage = styled("img")(() => ({
    maxWidth: "100%",
    objectFit: "cover",
}));

interface ImageProps {
    src?: string;
    alt: string;
    styles?: SxProps;
    notifyError?: (isError: boolean) => void;
    notifyLoaded?: (isLoaded: boolean) => void;
}

const PreviewImage = ({ src, alt, styles, notifyError, notifyLoaded }: ImageProps) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleLoad = () => {
        setIsLoaded(true);
        notifyLoaded?.(true);
    };

    const handleError = () => {
        setIsError(true);
        notifyError?.(true);
    };

    useEffect(() => {
        notifyLoaded?.(!src);
    }, [src]);

    return (
        <Box
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            sx={{
                ...customImageWrapperStyles,
                ...styles,
            }}
        >
            {src && !isLoaded && !isError && <CircularProgress color="primary" />}

            {src && !isError ? (
                <Fade in={isLoaded} timeout={1000}>
                    <StyledImage
                        key={src}
                        src={src}
                        alt={alt}
                        onLoad={handleLoad}
                        onError={handleError}
                        style={{
                            display: isLoaded ? "block" : "none",
                        }}
                    />
                </Fade>
            ) : (
                <Typography
                    sx={{ fontSize: { xs: "0.5rem", sm: "0.7rem", md: "1rem" } }}
                    color="secondary"
                    variant="inherit"
                    height={{ xs: "32vw", md: "15vw" }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    No Image
                </Typography>
            )}
        </Box>
    );
};

export default PreviewImage;
